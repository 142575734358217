import {Box, Icon, Image, useMediaQuery, useOutsideClick} from "@chakra-ui/react";
import placeholder from "../../Images/palceholder.png";
import * as React from "react";
import NeonText from "../Atoms/Neon/NeonText";
import {Verified} from "../../Icons/Icon";
import {MotionBox} from "../../CONSTANTS/animation_constants";
import {useState} from "react";
import {AnimateSharedLayout} from "framer-motion";
import {useNavigate} from "react-router-dom";

interface LocationState {
    id: string,
    image: string
}

interface DropsOverviewProps {
    drop: {
        tree: {
            image: string
        },
        name: string,
        mint_start_date: string,
        mint_end_date: string,
        website: string,
        description: string,
        id: string,
    },
    id: number,
    dropRef?: any,
    width?: number,
    showDetails? : boolean,
    presentation?: string
}


const DropsOverview: React.FC<DropsOverviewProps> = ({
                                                          drop,
                                                          id,
                                                          dropRef,
                                                          width,
                                                          showDetails= true,
                                                          presentation = "carousel"

}) => {
    const navigate = useNavigate()
    const overviewRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLessThan640] = useMediaQuery('(max-width: 640px)', {ssr: false})
    const defaultDate = "Not Expiring"

    useOutsideClick({
        // @ts-ignore
        ref: overviewRef,
        handler: () => setIsExpanded(false),
    })

    const collapseDate = () => {
        setIsExpanded(false);
    };

    const expandDate = () => {
        setIsExpanded(true);
    };

    const dateValue = drop.mint_end_date ? `End date: ${drop.mint_end_date || defaultDate}` : `Date: ${drop.mint_start_date}`

    return (
        <Box
            ref={dropRef}
            scrollSnapAlign={"start"}
            key={`${id}name`}
        >
            <AnimateSharedLayout >
                    <a href={`/drop/${drop.id}`}>
                    <Box
                        borderRadius={"20px"}
                        position={"relative"}
                        cursor={"pointer"}
                        overflow={presentation === "list" ? "visible" : "hidden"}
                    >
                        <MotionBox
                            whileHover={presentation === "list" ? {} : {
                                scale: 1.2,
                                transition: {duration: 0.25},
                            }}
                            layoutId="drop"
                            onClick={() => navigate(`/drop/${drop.id}`, {
                            state: {
                                dropId: drop.id
                            },
                        })}
                        >
                            <Image
                                borderRadius={"20px"}
                                boxSize={width}
                                fallbackSrc={placeholder}
                                src={drop.tree.image}
                                marginRight={"0rem"}
                            />
                        </MotionBox>
                        {showDetails &&
                            <Box>
                                <Box>

                                </Box>
                                <MotionBox
                                    position={"absolute"} bottom={"3"} left={"5"}
                                    padding={2}
                                    borderRadius={8}
                                    backgroundColor={'rgba(0, 0, 0, 0.3)'}
                                    width={"90%"}
                                >
                                    <Box display={"flex"} flexDirection={"row"}>
                                        <NeonText marginRight={"2"} textValue={`Name: ${drop.name}`}/>
                                        <Icon as={Verified}/>
                                    </Box>
                                    <NeonText  fontSize={"sm"} fontWeight={"100"}
                                               textValue={dateValue}/>
                                </MotionBox>
                                <Box height={"20px"} width={"100%"} style={{filter: "blur(5px)"}} position={"absolute"} bottom={"5"} left={"7"}>

                                </Box>
                            </Box>
                        }
                    </Box>
                    </a>
            </AnimateSharedLayout>
        </Box>
    )
}

export default DropsOverview;