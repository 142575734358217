// @ts-nocheck
import * as React from "react";
import { useRef, useEffect } from 'react';
import {
    forceSimulation, forceManyBody, forceLink,
    forceCollide, forceCenter, forceY, forceX, select, drag, zoom, create, scaleOrdinal, scaleLinear, max, schemeCategory10
} from "d3"
import {NODE_COLORS, TOOLTIP_COLORS} from "../../CONSTANTS/color_constants";
import {formatDigits} from "../../utils/formatter";
import image from "../../Images/starry_night_2.jpeg"

interface HistoricalDataGraphProps {
    data: {
        nodes: [],
        links: []
    },
    height: any,
    width: any,
    onDoubleClick({}): any,
    onClick({}): any
}

// const usedColors = new Set();
//
// function  getRandomColorsArray (colorArray: string[]) {
//     let color = colorArray[Math.floor(Math.random() * colorArray.length)]; // Pick a random color from the array
//     while (usedColors.has(color)) { // If the color has been used before, pick a new one
//         color = colorArray[Math.floor(Math.random() * colorArray.length)];
//     }
//     usedColors.add(color); // Add the new color to the set of used colors
//     return color;
// }
function generateTooltipContent(d) {
    let content = "";
    let color;

    // console.log("d.stats", d.stats);
    // console.log("d.additional_data", d.additional_data);
    if (d.additional_data?.name) {
        content += `<p>Name:<strong> ${d.additional_data.name}</strong></p>`;
    }
    // if (d.stats.total_supply) {
    //     content += `<p><strong>Items:</strong> ${d.stats.total_supply}</p>`;
    // }

    if (d.stats?.floor_price) {
        // console.log("d.stats.floor_price", d.stats.floor_price);
        content += `<p>FP:<strong> ${d.stats.floor_price} ${d.stats.accounting_unit}</strong></p>`;
    }

    if (d.stats?.volume_24h) {
        // console.log("d.stats?.volume_24h", d.stats?.volume_24h);
        content += `<p>Volume 24h:<strong> ${formatDigits(d.stats?.volume_24h)} ${d.stats.accounting_unit}</strong></p>`;
    }

    if (d.stats?.volume_change_24) {
        // console.log("d.stats?.volume_24h", d.stats?.volume_change_24);
        if(d.stats.volume_change_24 < 0) color = "red"
        if(d.stats.volume_change_24 > 0) color = "green"
        content += `<p style="color: rgba(255,255,255, 0.7)">Volume Change 24h: <strong style="color: ${color}">${formatDigits(d.stats.volume_change_24, true)} %</strong></p>`;
    }

    if (d.stats?.num_owners) {
        content += `<p>Owners: <strong>${d.stats.num_owners}</strong></p>`;
    }

    if (d.stats?.total_listed) {
        content += `<p>Listed: <strong>${d.stats.total_listed}</strong></p>`;
    }

    return content;
}

const HistoricalDataGraph: React.FC<HistoricalDataGraphProps> = ({data, height, width, onDoubleClick, onClick}) => {
    const svgRef = useRef<SVGSVGElement | null>(null);
    let nodes;
    let links;
    let simulation;
    let clickTimeout;
    let delayTimeout;
    // console.log("data", data);
    const mappedUltimateOwners = data.nodes.map((node, i) => {
        return node.additional_data.ultimateOwner
    })

    // console.log("mappedUltimateOwners", mappedUltimateOwners);

    const tempNodes = data.nodes.map((node: any) => {
        return {
            id: node.id,
            additional_data: node.additional_data,
            created_at: node.created_at,
            edge_active: node.edge_active,
            stats: node.stats,
        }
    })
    // console.log("tempNodes", tempNodes);
    const tempLinks = data.links.map((link: any) => {
        return {
            id: link.id,
            additional_data: link.additional_data,
            source: link.from_id,
            target: link.to_id,
            created_at: link.created_at
        }
    })

    useEffect(() => {
        if(simulation) {
            simulation.stop()
            setTimeout(() => renderForcedSimulation(), 200)
        }
    }, [data])

    useEffect(() => {
        renderForcedSimulation()
        // not working
        // return () => { tooltip.remove() }
    }, [])

    const renderForcedSimulation = () => {
        simulation = forceSimulation(tempNodes)
            .force("link", forceLink(tempLinks).id((d: any) => d.additional_data.id))
            .force("charge", forceManyBody(tempNodes).strength(-200))
            .force("center", forceCenter(width/2, height/2))
            .force("x", forceX(width/2))
            .force("y", forceY(height/2))
            .force("collide", forceCollide().radius(d => {
                if(d.stats === null) return 10
                return 50
            }))
            .alpha(1).restart();

        const svg = select(svgRef.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height)


        // Zoom behaviour
        const zoomed = (event) => {
            nodes.attr("transform", event.transform);
            // show pictures only when zoom scale is bigger than 3
            if (event.transform.k > 2) {
                const visibleNodes = nodes.filter((d, i, n) => {
                    const rect = n[i].getBoundingClientRect();
                    return (
                        rect.top >= 0 &&
                        rect.left >= 0 &&
                        rect.bottom <= (height || document.documentElement.clientHeight) &&
                        rect.right <= (width || document.documentElement.clientWidth)
                    );
                });
                visibleNodes.style("fill", d => `url(#${d.id})`)
                nodes.filter((d, i, n) => !visibleNodes.nodes().includes(n[i])).style("fill",  d =>`url(#filter-${d.id})`);
            } else {
                nodes.style("fill",  d =>`url(#filter-${d.id})`);
            }
            links.attr("transform", event.transform);
        }

        const backgroundImage = svg.append("defs")
            .append("pattern")
            .attr("id", "image-pattern")
            .attr("width", "100%")
            .attr("height", "100%")

        backgroundImage.append("image")
            .attr("xlink:href", image)
            .attr("preserveAspectRatio", "xMidYMid slice");

        const zoomFunction = zoom()
            .scaleExtent([0.5, 64])
            .on("zoom", zoomed);

        const zoomRect = svg
            .append("rect")
            .attr("width", width)
            .attr("height", height)
            .attr("fill", "url(#image-pattern)") // Set the desired background color or "url(#image-pattern)"
            .attr("opacity", 0.4)

        zoomRect.call(zoomFunction);

        // Create the drag behavior
        const draggableFunction =
            drag<SVGCircleElement, Node>()
                .on('start', (event, d) => {
                    if (!event.active) simulation.alphaTarget(0.3).restart();
                    d.fx = d.x;
                    d.fy = d.y;
                })
                .on('drag', (event, d) => {
                    d.fx = event.x;
                    d.fy = event.y;
                })
                .on('end', (event, d) => {
                    if (!event.active) simulation.alphaTarget(0);
                    d.fx = null;
                    d.fy = null;
                });

        const color = scaleOrdinal()
            .domain(mappedUltimateOwners)
            .range(NODE_COLORS)

        links = svg
            .append('g')
            .attr('class', 'links')
            .selectAll('line')
            .data(tempLinks)
            .enter()
            .append('line')
            .attr('stroke-width', 1)
            .attr("stroke-opacity", 1)
            .style("stroke", "#FFFFFF")

        // Create a tooltip
        const tooltip = create('rect')
            .attr('class', 'tooltip')
            .style("position", "absolute")
            .style("background-color", TOOLTIP_COLORS.BACKGROUND)
            .style("border", "1px solid rgba(255,255,255, 0.3)")
            .style("border-radius", "5px")
            .style("padding", "5px")

        const sizeRange = [20, 120];

        const nodeSizeScale = scaleLinear()
            .domain([0, max(tempNodes, (d) => {
                if(d.stats === null) return 1
                return Number(d.stats?.volume_24h)
            })])
            .range(sizeRange);

        const defs = svg.append('defs');
        // One image per node
        defs.selectAll("pattern")
            .data(tempNodes)
            .join(
                enter => {
                    // For every new <pattern>, set the constants and append an <image> tag
                    const patterns = enter
                        .append("pattern")
                        .attr("width", (d) => {
                            // console.log("d.stats", d.stats);
                            if(d.stats?.volume_24h === null) return 1
                            return 1
                        })
                        .attr("height", (d) => {
                            if(d.stats?.volume_24h === null) return 1
                            // if(d.stats?.volume_24h === null) return 1
                            return 1
                        })
                    patterns
                        .append("image")
                        .attr("x", 0)
                        .attr("y", 0)
                        .attr("width", (d) => {
                            // console.log("d.stats?.volume_24h === null", d.stats);
                            if(!d.stats) return nodeSizeScale(20) * 2
                            return nodeSizeScale(Number(d.stats?.volume_24h)) * 2
                        })
                        .attr("height", (d) => {
                            if(!d.stats) return nodeSizeScale(20) * 2
                            return nodeSizeScale(Number(d.stats?.volume_24h)) * 2
                        })
                    return patterns;
                }
            )
            // For every <pattern>, set it to point to the correct
            // URL and have the correct (node) ID
            .attr("id", d => d.id)
            .select("image")
            .attr("xlink:href", d => {
                // console.log("d.additional_data.image", d.additional_data.image);
                return d.additional_data.image
            })

        nodes = svg.append("g")
            .attr("opacity", 1)
            .selectAll("circle")
            .data(tempNodes)
            .join("circle")
            .attr("r", (d) => {
                if(d.stats === null) return nodeSizeScale(20)
                return nodeSizeScale(Number(d.stats?.volume_24h))
            })

            // Adding starlight effects to each node
            .style("fill",  d =>`url(#filter-${d.id})`)
            .each(d => {
                // Create the radial gradient for the node fill
                const gradient = svg.append("defs")
                    .append("radialGradient")
                    .attr("id", `filter-${d.id}`)
                    .attr("cx", "50%")
                    .attr("cy", "50%")
                    .attr("r", "50%");

                // Add gradient stops for the fill color and opacity
                gradient.append("stop")
                    .attr("offset", "0%")
                    .style("stop-color", color(d.additional_data.ultimateOwner)) // Outer color and full opacity
                    .style("stop-opacity", 1)

                gradient.append("stop")
                    .attr("offset", "100%")
                    .style("stop-color", color(d.additional_data.ultimateOwner))
                    .style("stop-opacity", 0)
            })

            .on("mouseover", function (event, d: any) {
                select(this).style('cursor', `grab`)
                delayTimeout = setTimeout(() => {
                    links.attr("stroke-opacity", l => (l.source === d || l.target === d) ? 1 : 0.3);
                    links.attr("stroke-width", l => (l.source === d || l.target === d) ? 2 : 1);
                    nodes.filter((n) => n.id !== d.id).attr("opacity", 0.3)
                    nodes.filter((n) => n.id === d.id).style("fill", d => `url(#${d.id})`)
                    // adding tooltip
                    select('body').append(() => tooltip.node())
                    tooltip.html(generateTooltipContent(d))
                    // Show the tooltip and set its text and position
                    tooltip.style("color", TOOLTIP_COLORS.TEXT)
                }, 300)
            })
            .on("click", (event, d) => {
                clearTimeout(clickTimeout);
                // checkout how to present other things/tooltip design and data presentation, such as episodes of cartoon or comics
                if(Number(d.stats.number_of_owners) === 0) return
                clickTimeout = setTimeout(function() {
                    onClick({
                        data: d,
                        left: event.pageX + 100,
                        top: event.pageY - 200,
                    })
                }, 300)
            })
            .on("dblclick", (event, d) => {
                // console.log("show me what you gooot", d.additional_data);
                clearTimeout(clickTimeout);
                onDoubleClick(d.additional_data.droppedInDropId[0])
                tooltip.remove()
                // take to the singleDrop page details
                // onNodeClick(d.data.nftDropId)
            })
            .on("mousemove", (event, d: any) => {
                // position of tooltip
                tooltip
                    .style("left", `${event.pageX + 100}px`)
                    .style("top", `${event.pageY - 200}px`);
            })
            .on("mouseleave", (event, d: any) => {
                tooltip.remove()
                clearTimeout(delayTimeout)
                nodes.filter((n) => n.id === d.id).style("fill",  d =>`url(#filter-${d.id})`)
                // Reset the opacity and width of all the links
                links.attr("stroke-opacity", 1)
                    .attr("stroke-width", 1)
                // Reset the opacity of all the nodes
                nodes.attr("opacity", 1)
                select(this).style('cursor', `default`)
            })
            .call(draggableFunction)

        simulation.on("tick", () => {
            links
                .attr('x1', (d: any) => d.source.x)
                .attr('y1', (d: any) => d.source.y)
                .attr('x2', (d: any) => d.target.x)
                .attr('y2', (d: any) => d.target.y);
            nodes
                .attr('cx', (d: any) => d.x)
                .attr('cy', (d: any) => d.y)
        })

        // add rerender if nodes, links width or height are changed
        // need to remove previous svg before rendering new
        simulation.restart()
    }

    return (
            <svg
                cursor={"zoom-in"}
                ref={svgRef}
                viewBox={`0 0 ${width} ${height}`}
            />
    )
}

export default HistoricalDataGraph;