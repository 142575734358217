import React from "react";
import {BrowserRouter as Router} from "react-router-dom";

import './index.css';
import "./fonts/Exo2/Exo2.ttf"
import AnimatedRoutes from "./Components/Organism/AnimatedRoutes";

function App() {
    return (
        <Router>
            <AnimatedRoutes/>
        </Router>
    );
}

export default App;


