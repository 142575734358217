import {useInfiniteQuery} from 'react-query';
import { getCollectionByName } from "../../axios/collections-service";
import {GET_COLLECTION_BY_NAME} from "../../queryConstants";

export const useGetCollectionByName = (name: string) => {
    return useInfiniteQuery([GET_COLLECTION_BY_NAME, name], ({pageParam = 1}) => getCollectionByName(pageParam, name), {
        getNextPageParam: (lastPage, allPages) => {
            const nextPage =
                lastPage.length === 15 ? allPages.length + 1 : undefined;
            return nextPage;
        },
        staleTime: Infinity,
        enabled: false
    });
    // return useQuery([GET_COLLECTION_BY_NAME, name], () => getCollectionByName(name), {
    //     staleTime: Infinity,
    //     enabled: false
    // });
};

