import NeonText from "../Neon/NeonText";
import {Box} from "@chakra-ui/react";
import * as React from "react";
import {motion} from "framer-motion";

const MotionBox = motion(Box)

interface NeonSeparatorProps {
    name: string;
    paddingTop? :string,
    paddingBottom? :string,
}

const NeonSeparator: React.FC<NeonSeparatorProps> = ({ name, paddingTop= "10", paddingBottom= "10"}) => {

    return (
        <MotionBox
            initial={{ x: "-100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { duration: 0.2, ease: "easeInOut"}}}
            exit={{ opacity: 0 }}
            paddingTop={paddingTop} paddingBottom={paddingBottom}
        >
            <Box>
                <NeonText fontSize={"lg"} textValue={name} textShadow={"0 0 0.2em #fff"} marginLeft={"10"}/>
                <Box boxShadow={"0 0 0.07em #fff"} borderRadius={"10"} height={"0.4vh"} width={"80%"} bgColor={"white"}/>
            </Box>
        </MotionBox>
    )
};

export default NeonSeparator;