import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Button, HStack, Icon, Skeleton, useDisclosure, useMediaQuery, useToast} from "@chakra-ui/react";
import {AddIcon, ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import _ from "lodash";
import {useAutomaticTyping, useWindowDimensions} from "../../hooks/hooks";
import {MotionBox} from "../../CONSTANTS/animation_constants";
import DropsOverview from "./DropsOverview";
import {useGetNftDrops} from "../../services/queries/nftDrops/useGetNftDrops";
import {isMobile} from "../../utils/helper-util";
import NeonText from "../Atoms/Neon/NeonText";
import ModalForm from "../Modals/ModalForm";
import {
    useReportDropMessage,
    useReportNftDrop
} from "../../services/queries/nftDrops/useReportNftDrop";
import {DROP_REPORTS} from "../../CONSTANTS/string_constants";

interface CarouselProps {}

const Carousel: React.FC<CarouselProps> = () => {
    const carouselRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const imageRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [sentFrom, setSentFrom] = useState("")
    const [dropInfo, setDropInfo] = useState("")
    const [challengeResult, setChallengeResult] = useState("")
    const toast = useToast()
    const isMob = isMobile()
    const [mobile, setMobile] = useState(false)
    const { width } = useWindowDimensions();
    const [drops, setDrops] = useState([]);
    const [carouselWidth, setCarouselWidth] = useState(width * 0.85);
    const [imageWidth, setImageWidth] = useState(width * 0.2)
    const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)', {ssr: false})
    const [isLargerThan960] = useMediaQuery('(min-width: 960px)', {ssr: false})
    const [isLargerThan640] = useMediaQuery('(min-width: 640px)', {ssr: false})
    const {isSuccess, isError, data: dropsData, refetch} = useGetNftDrops()
    const { data: reportedData, status: reportedStatus, mutate: mutateReportNftDrop, isLoading: loadingReport} = useReportNftDrop()
    const {mutate: mutatePostMessage, status: messageStatus} = useReportDropMessage(sentFrom, DROP_REPORTS.REPORT_NEW_DROP, dropInfo, reportedData?.challengeId, challengeResult)

     // console.log("dropsData", dropsData);

    let position = 0;

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        setMobile(isMobile)
    }, [isMob])

    useEffect(() => {
        if(isError) toast({
            id: "carousel-error",
            title: "Error!",
            description: "Ooops, we cannot fetch carousel data right now!",
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
    }, [isError])

    useEffect(() => {
        // @ts-ignore
        if(!_.isEmpty(dropsData?.data) && isSuccess) {
            setDrops(dropsData.data.sort((a: any, b: any) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            }).slice(0, 16));
            dropCountMessages()
        }
    }, [dropsData])

    useEffect(() => {
        setCarouselWidth(width * 0.85)
        if(isLargerThan1280) return setImageWidth(width * 0.85 / 4 -7)
        if(isLargerThan960) return setImageWidth(width * 0.85 / 3 -7)
        if(isLargerThan640) return setImageWidth(width * 0.85 / 2 -7)
        if(!isLargerThan640) return setImageWidth(width * 0.85 -7)
    }, [width])

    const dropCountMessages = () => {
        if(dropsData?.one_day) {
                toast({
                    id: "oneDayCountMsg",
                    title: "New Drops Added  🚀  🌕",
                    description: `Last 24h: ${dropsData?.one_day}`,
                    status: 'info',
                    position: "top-left",
                    variant: "left-accent",
                    duration: 5000,
                    isClosable: true,
                })
        }
    }

    const handleArrowSelection = (direction: string) => {
        // carouselWidth / imageRef.current.scrollWidth > 4 ? : : carouselWidth / imageRef.current.scrollWidth
        const numberOfImages = Math.floor(carouselWidth / imageRef.current.scrollWidth)
        const totalCarouselWidth = drops.length * imageRef.current.scrollWidth
        if(direction === "right") {
            if(position >= totalCarouselWidth - imageRef.current.scrollWidth * numberOfImages) return
                carouselRef.current?.scrollTo(position + carouselWidth, 0);
                position = position + carouselWidth
        }

        if(direction === "left") {
            if(position <= 0) return position = 0
            carouselRef.current?.scrollTo(position - carouselWidth, 0);
            position = position - carouselWidth
        }
    };
    const getChallenge = async () => {
        await mutateReportNftDrop()
    }

    useEffect(() => {
        if(reportedData && reportedStatus === "success") onOpen()
    }, [reportedStatus, reportedData])
    const sendReport = () => {
        mutatePostMessage()
    };

    useEffect(() => {
        if(messageStatus === "success") {
            setDropInfo("")
            setSentFrom("")
            setChallengeResult("")
            return onClose()
        }
    }, [messageStatus])

    return (
        <MotionBox
            position={"relative"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1, ease: "easeInOut", delay: 2} }}
            exit={{ opacity: 0 }}
        >
            <HStack justifyContent={"space-between"}>
                <NeonText fontStyle={"italic"} fontWeight={"500"} fontSize={"3xl"} textShadow={"0 0 0.2em #fff"} textValue={useAutomaticTyping(250, "Latest drops")} />
                <Button isLoading={reportedStatus === "loading" || loadingReport} borderRadius={10} onClick={() => getChallenge()}>
                    <AddIcon boxSize={5} />
                </Button>
            </HStack>
            <Button zIndex={1} boxShadow={"0 0 0.35em #fff"} backgroundColor={"black"}
                    borderRadius={!mobile ? "2.5rem": "5rem"} width={!mobile ? "5rem" : "5rem"} height={!mobile ? "5rem" : "5rem"}
                    onClick={() => handleArrowSelection("left")} position={"absolute"} top={!mobile ? "12rem" : "10rem"} left={!mobile ? "-2rem": "-1.6rem"}>
                <Icon boxSize={!mobile ? "3rem": "5rem"} as={ChevronLeftIcon}/>
            </Button>
            {_.isEmpty(drops) ?
                <HStack>
                    {[...Array(Math.floor(carouselWidth < 350 ? 1 : carouselWidth/350))].map((c, i) => {
                        return (
                            <Skeleton key={`${i}`} startColor='black' boxSize={!mobile ? ["150px","200px","250px","350px"] : "200px"}
                                      borderRadius={"20px"}/>
                        )
                    })
                    }
                </HStack> :
                <HStack
                    ref={carouselRef}
                    display={"inline-list-item"}
                    w={carouselWidth}
                    overflow={"hidden"}
                    scrollBehavior={"smooth"}
                    overscrollBehavior={"contain"}
                    scrollSnapType={"x mandatory"}
                >
                    {drops.map((drop: {
                        tree: { image: string },
                        mint_start_date: string,
                        mint_end_date: string,
                        name: string, id: string,
                        description: string, website: string
                    }, i: number) => {
                        if (!drop.name) return
                        return (
                            <DropsOverview
                                key={`${i}${drop.name}`}
                                width={imageWidth}
                                dropRef={imageRef}
                                id={i}
                                drop={drop}
                            />
                        )
                    })
                    }
                </HStack>
            }
            <Button zIndex={1} boxShadow={"0 0 0.35em #fff"} backgroundColor={"black"}
                    borderRadius={!mobile ? "2.5rem": "5rem"} width={!mobile ? "5rem" : "5rem"} height={!mobile ? "5rem" : "5rem"}
                    onClick={() => handleArrowSelection("right")} position={"absolute"} top={!mobile ? "12rem" : "10rem"} right={!mobile ? "-2rem": "-1.6rem"}>
                <Icon boxSize={!mobile ? "3rem": "5rem"} as={ChevronRightIcon}/>
            </Button>
            <ModalForm
                title={"Report New Drop"}
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                send={sendReport}
                setFrom={setSentFrom}
                setDropInfo={setDropInfo}
                setChallResult={setChallengeResult}
                challengeQuestion={reportedData?.challengeText}
                sendBtnLoader={messageStatus}
            />
        </MotionBox>
    )
}

export default Carousel;