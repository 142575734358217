import React, {useEffect, useState, useRef} from "react";
import ReactGA from "react-ga4";

import {Box} from "@chakra-ui/react";
import {useAppDispatch, useAppSelector} from "../../hooks/reducerHooks";
import {fetchHistoricalData, history, status} from "./HistoriographSlice";
import {DATA_STATUS} from "../../CONSTANTS/string_constants";
import WholePageSpinner from "../../Components/Modals/WholePageSpinner";
import HistoricalDataGraph from "../../Components/Organism/HistoricalDataGraph"
import DraggableBox from "../../Components/Atoms/DraggableBox";
import HistoriographFilter from "../../Components/Organism/HistoriographFilter";
import AirdropManagerLogo from "../../Components/Atoms/AirdropManagerLogo";
import Tooltip from "../../Components/Atoms/Tooltip";

const Historiograph = () => {
    const dispatch = useAppDispatch();
    const historiographData = useAppSelector(history)
    const historiographDataStatus = useAppSelector(status)
    const [organizedHistoricalData, setOrganizedHistoricalData] = useState<any>({nodes: [], links: []})
    const [volume, setVolume] = useState<number>(100);
    const [fp, setfp] = useState<number>(0.1);
    const [volumeChange, setVolumeChange] = useState<number>(0);
    const [selectedDrops, setSelectedDrops] = useState<any>([])

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        dispatch(fetchHistoricalData())
        ReactGA.send({ hitType: "pageview", page: "/historiograph", title: "Historiograph" });
    }, [])

    useEffect(() => {
        if(historiographData.length > 0) {
            const forcedGraph: {nodes: any[], links: any[]} = {nodes: [], links: []}
            historiographData.map((obj: any) => {
                if(obj.record_type === "node") {
                    forcedGraph.nodes.push(obj)
                }
                if(obj.record_type === "edge") {
                    forcedGraph.links.push(obj)
                }
            })
            setOrganizedHistoricalData({nodes: forcedGraph.nodes, links: forcedGraph.links})
        }
    }, [historiographData])
    // navigate(`/drop/${id}`, {state: {dropId: id}})
    const handleDoubleClick = (id: string) => window.open(`/drop/${id}`, '_blank')

    const handleClick = (eventData: any) => {
        setSelectedDrops((prevState: any) => {
            return [...prevState, eventData]
        })
    }

    const closeTooltip = (index: number) => {
        const tempArr = selectedDrops.filter((drop: object, i: number) => i !== index)
        setSelectedDrops(tempArr)
    }
    // define upper limit of tooltips presented on screen

    if(historiographDataStatus === DATA_STATUS.LOADING) return <WholePageSpinner height={"100vh"} width={"100vw"} showParachute={false}/>
    return (
            <Box>
                <Box position={"absolute"}>
                    <AirdropManagerLogo marginLeft={17} marginTop={17}/>
                </Box>
                {selectedDrops.length > 0 &&
                    selectedDrops.map((drop: any, i: number) => (
                        <DraggableBox key={`key-${i}`} index={i} top={drop.top} left={drop.left}
                                      item={
                                          <Tooltip
                                              onClose={() => closeTooltip(i)}
                                              index={`key-${i}`}
                                              data={drop.data}
                                          />}
                        />

                    ))
                }
                {/*<DraggableBox key={"item"} index={1}*/}
                {/*              item={*/}
                {/*    <HistoriographFilter*/}
                {/*        onVolume={setVolume}*/}
                {/*        onFp={setfp}*/}
                {/*        onVolumeChange={setVolumeChange}*/}
                {/*    />}*/}
                {/*/>*/}
                {
                    Object.keys(organizedHistoricalData.nodes).length <= 0 ? null :
                    <HistoricalDataGraph height={windowSize[1]}
                                         width={windowSize[0]}
                                         data={organizedHistoricalData}
                                         onDoubleClick={handleDoubleClick}
                                         onClick={handleClick}
                    />
                }
            </Box>
    )
}

export default Historiograph;