import {motion} from "framer-motion";
import {Box, Link, Text, Button} from "@chakra-ui/react";

export const TRANSITION_ANIMATION = {
    EXIT: { duration: 0.3, ease: "easeInOut"},
}

export const CHAINS = {
    ETHEREUM: "Ethereum",
    SOLANA: "Solana"
}

export const MotionBox = motion(Box)
export const MotionText = motion(Text)
export const MotionLink = motion(Link)
export const MotionButton = motion(Button)