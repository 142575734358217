import {Icon} from "@chakra-ui/react";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {AirdropManagerLogo as logo} from "../../Icons/Icon"
import {MotionBox, TRANSITION_ANIMATION} from "../../CONSTANTS/animation_constants";


interface AirdropManagerLogoProps {
    hover?: boolean,
    marginLeft?: number,
    marginTop?: number
}
const AirdropManagerLogo: React.FC<AirdropManagerLogoProps> = ({hover = true, marginLeft = 0, marginTop = 0 }) => {
    const navigate = useNavigate()
    return (
        <MotionBox
            initial={{opacity: 0, scale: 0.3 }}
            animate={{ opacity: 1, scale: 1, transition: { duration: 0.3, ease: "easeInOut", delay: 5}}}
            exit={{ scale: 1, opacity: 0, transition: TRANSITION_ANIMATION.EXIT}}
            marginTop={marginTop} marginLeft={marginLeft}
            cursor={hover ? "pointer" : ""}
            _hover={{
                filter: hover ? "url(#logoShadow)" : ""
            }}
            onClick={() => hover ? navigate("/") : {}}
        >
            <Icon
                as={logo}
                zIndex={10}
            />
        </MotionBox>
    )
};

export default AirdropManagerLogo;