import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {getHistoricalData} from "./HistoriographAPI";

export interface Historiograph {
    history: any
    status: 'idle' | 'loading' | 'failed';
}

const initialState: Historiograph = {
    history: [],
    status: "idle",
}

export const fetchHistoricalData = createAsyncThunk(
    `historicalData/fetchHistoricalData`,
    async () => {
        const response = await getHistoricalData();
        return response
    }
)
export const historicalDataSlice = createSlice({
    name: "historicalData",
    reducers: {},
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchHistoricalData.pending, (state) => {
                state.status = "loading"
            })
            .addCase(fetchHistoricalData.fulfilled, (state, action) => {
                state.status = 'idle';
                state.history = action.payload
            })
    }
})

export const history = (state: RootState) => state.historicalData.history
export const status = (state: RootState) => state.historicalData.status

export default historicalDataSlice.reducer