export const BUTTON_COLORS = {
    NEON_RED: "#ff073a",
    NEON_BLUE: "#04d9ff",
    REGULAR_RED: "#f40",
    REGULAR_BLUE: "#08f",
    WHITE: "#FFF"
}

export const TOOLTIP_COLORS = {
    // gray background #282828
    BACKGROUND: `#000000`,
    BACKGROUND_SECOND: `#1f2020`,
    TEXT: "rgba(255,255,255, 0.7)"
}

export const NODE_COLORS = [
    "#F8F988",
    "#FFEA20",
    "#FFF56D",
    "#FFDF6B",
    "#FFD36E",
    "#FF9551",
    "#FF9A8C",
    "#FFCAC8",
    "#FF9E9E",
    "#FF6464",
    "#FF4A4A",
    "#CE1F6A",
    "#F8FFDB",
    "#D8F8B7",
    "#C0EEE4",
    "#9DF1DF",
    "#99FFCD",
    "#B3FFAE",
    "#B4FE98",
    "#6FEDD6",
    "#77E4D4",
    "#B9FFF8",
    "#E3F6FF",
    "#8DCBE6",
    "#9FB4FF",
    "#B4AEE8",
    "#998CEB",
    "#FFE3FE",
    "#FF79CD",
    "#C67ACE",
    "#AA2EE6",
    "#93329E",
    "#440A67",
    "#23049D",
]