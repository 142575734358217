import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Icon,
    Image,
    Input,
    Popover,
    Spinner,
    useOutsideClick,
    PopoverTrigger, Portal, PopoverContent, useMediaQuery
} from "@chakra-ui/react";
import {MotionBox, TRANSITION_ANIMATION} from "../../CONSTANTS/animation_constants";
import _ from "lodash";
import { useAnimationControls } from "framer-motion"
import NeonText from "../Atoms/Neon/NeonText";
import * as React from "react";
import {useEffect, useState} from "react";
import {useGetCollectionByName} from "../../services/queries/collections/useGetCollectionByName";
import { Verified } from "../../Icons/Icon";
import placeholderImage from "../../Images/palceholder.png";
import {useAutomaticTyping, useDebounce} from "../../hooks/hooks";
import {useInView} from "react-intersection-observer";
import customize from "../../Images/customize.png";

interface NftDropdownInputProps {
    label?: string,
    onSave({}): any,
    onChange(e: any): any,
    disabled?: boolean,
    isError?: { errorMessage: string },
    marginRight?: string,
    search: any,
    animation: boolean,
    placeholder: string,
    customDropAdded: boolean,
}

const NftDropdownInput: React.FC<NftDropdownInputProps> = ({
                                                               onSave,
                                                               disabled = false,
                                                               isError = {},
                                                               onChange,
                                                               search,
                                                               animation,
                                                               placeholder,
                                                               customDropAdded,
}) => {
    const dropdownInputref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const controls = useAnimationControls()
    const [showSearchView, setShowSearchView] = useState<boolean>(false)
    const { refetch, hasNextPage, data, isLoading, isFetching, isFetchingNextPage, isError: collectionError, fetchNextPage } = useGetCollectionByName(search);
    const debouncedSearch = useDebounce(search, 750);
    const [collectionName, setCollectionName] = useState<undefined | string>(undefined)
    const [isLargerThan640] = useMediaQuery('(min-width: 640px)', {ssr: false})
    const { ref, inView } = useInView();

    useEffect(() => {
        if(animation) { controls.start({ opacity: 1, x: 0, transition: { duration: 0.2, ease: "easeInOut", delay: 0.2} })
        } else {
            controls.start({opacity: 0, x: "-10%", transition: {duration: 0.2, ease: "easeInOut", delay: 0.2}})
        }
    }, [animation])

    useEffect(() => {
        setCollectionName(undefined)
    }, [placeholder])

    const handleInputChange = (e:any) => {
        // setSearch(e.target.value)
        setShowSearchView(true)
        onChange(e)
    }

    useOutsideClick({
        // @ts-ignore
        ref: dropdownInputref,
        handler: () => setShowSearchView(false),
    })

    // add isVerified
    const selectCollection = (d: {name: string, id: string, image:string, }) => {
        // console.log(d);
        onSave({
            name: d.name,
            id: d.id,
            image: d.image,
        })
        // setSearch(d.name)
        setShowSearchView(false)
    }

    const addCustomDrop = () => {
        if(data !== undefined &&_.isEmpty(data?.pages[0]) ) {
            onSave({
                custom_drop: true
            })
        }
    }

    useEffect(() => {
        // @ts-ignore
        refetch(debouncedSearch.toLowerCase())
    }, [debouncedSearch])

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView, fetchNextPage, hasNextPage]);

    console.log("search", search);

    console.log(collectionName);

    return (
        <Box marginBottom={"5"} marginRight={"3rem"}>
            <Box position={"absolute"} bottom={"8rem"}>
                {data?.pages[0].length === 0 && !customDropAdded && <NeonText fontSize={"xs"} maxW={"50%"} textValue={"If there is nothing returned from dropdown, you can still enter custom name drop, but we require at least image to be added"}/>}
            </Box>
            {data?.pages[0].length !== 0 && data?.pages[0].length !== undefined && !_.isEmpty(search) && showSearchView &&
                <Box ref={dropdownInputref} zIndex={1} borderRadius={"20px"} maxHeight={"50vh"} overflowY={"auto"} paddingY={"2"} paddingLeft={"5"} backgroundColor={"black"} position={"absolute"} bottom={"13vh"} left={0} border={"0.1rem solid #fff"}>
                    {data?.pages.map((page: any[], z: number) => page.map((d: {image: string, name:string, id: string, is_verified: boolean}, i: number) => {
                        return (
                                <Popover key={`${d.name}${i}`} trigger={"hover"}>
                                    <PopoverTrigger>
                                        <HStack ref={data.pages[z].length - 1 === 14  ? ref : null} alignItems={"center"} paddingX={["0", "0", "5", "5"]} key={`${i}-${d.name}`}
                                                zIndex={2} _hover={{backgroundColor: "black", opacity: 0.4}}
                                                onClick={() => {
                                                    selectCollection(d)
                                                    setCollectionName(d.name)
                                                }} marginY={"2"}>
                                            <Image fallbackSrc={placeholderImage} borderRadius={"10px"} boxSize={["20px"]}
                                                   src={d.image}/>
                                            <NeonText fontSize={["xs", "sm", "lg", "lg"]} textValue={d.name}
                                                      textShadow={"0 0 0.1em #fff"} marginLeft={"10"}/>
                                            {d.is_verified && <Box boxSize={["20px"]}><Icon as={Verified}/></Box>}
                                            {isFetchingNextPage ? <Spinner color='cyan.200' position={"absolute"} bottom={2} right={3}/> : null}
                                        </HStack>
                                    </PopoverTrigger>
                                    <Portal>
                                        <PopoverContent left={"15vw"} border={"0.5px"} borderColor={"white"}
                                                        boxShadow={["0 0 1.25em #fff"]} borderRadius={"20"}>
                                            <Box borderRadius={"20"} backgroundColor={"black"} boxSize={"inherit"}
                                                 borderColor={"white"} border={"2px"} padding={"5"}
                                                 boxShadow={["inset 0 0 1em 0 #fff"]}>
                                                <NeonText textValue={d.id} fontSize={"sm"}/>
                                            </Box>
                                        </PopoverContent>
                                    </Portal>
                                </Popover>
                            )
                        })
                    )}
                </Box>
            }
                <MotionBox
                    initial={{ x: "-10%", opacity: 0 }}
                    animate={controls}
                    exit={{ x: "-10%", opacity: 0, transition: TRANSITION_ANIMATION.EXIT }}
                    h='calc(10vh)' w='calc(25vw)'
                >
                    <FormControl
                        h={"inherit"}
                        w={"inherit"} borderRadius={"inherit"}
                        borderColor={"#fff"}
                                >
                        <Input
                            variant='flushed'
                            focusBorderColor="#04d9ff"
                            value={collectionName || search}
                            onChange={(e) => {
                                handleInputChange(e)
                            }}
                            onKeyDown={(e) => {
                                if(e.code === "Enter" || e.code === "NumpadEnter" && !customDropAdded){
                                    addCustomDrop()
                                }
                            }}
                            fontFamily={"Exo2"} _placeholder={{fontWeight: "500", textShadow: "0 0 0.07em #fff" }}
                            fontSize={["md","md","xl","xl", "xl"]} h={"inherit"} w={"inherit"} borderRadius={"inherit"}
                            fontStyle={"italic"} placeholder={placeholder}
                        />
                        <FormErrorMessage marginLeft={4}>{isError.errorMessage}</FormErrorMessage>
                    </FormControl>
                    {isLoading && search !== "" ? <Spinner size='lg' color='cyan.200' position={"absolute"} bottom={7} right={5}/> : null}
                </MotionBox>
        </Box>
    )
}

export default NftDropdownInput;