export const ERROR_MESSAGES = {
        STRING_ERROR: "String is required",
        NUMBER_ERROR: "Number is required",
        DATE_ERROR: "Correct format yyyy-mm-dd",
        TEXTAREA_STRING_ERROR: 'String expected, no less then 140 characters',
        TEXTAREA_LENGTH_ERROR: 'Minimum length should be 140',
        BOOLEAN_ERROR: "true or false",
}
export const DATA_STATUS = {
        IDLE: 'idle',
        LOADING: 'loading',
        FAILED: 'failed'
}

export const DROP_REPORTS = {
        REPORT_BAD_DROP: "Report a bad drop",
        REPORT_NEW_DROP: "Report a new drop",
}
// for type errors add regex to check if erc20 or nft -  'String expected, NFT, ERC20 etc.'
export const STANDARD_DROP_TYPE = ["Hold", "Burn", "Stake", "airdrop"]