import {useInfiniteQuery} from "react-query";
import {GET_AI_ANALYSIS} from "../../queryConstants";
import {getAiAnalysis} from "../../axios/ai-analysis";

export const useGetAIAnalysis = () => {
    return useInfiniteQuery([GET_AI_ANALYSIS], ({pageParam = 1}) => getAiAnalysis(pageParam), {
        getNextPageParam: (lastPage, allPages) => {
            const nextPage =
                lastPage.length === 10 ? allPages.length + 1 : undefined;
            return nextPage;
        },
        staleTime: Infinity,
        // enabled: true
    });
};