import {
    Box,
    HStack,
    Icon,
    Tbody,
    Td,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th, FormControl, Input, useMediaQuery, Spinner, VStack, useToast, useOutsideClick,
} from "@chakra-ui/react";
import ReactGA from "react-ga4";

import { useInView } from "react-intersection-observer";
import {useGetAllDrops} from "../../services/queries/nftDrops/useGetNftDrops";
import {useEffect, useState, useRef} from "react";
import * as React from "react";
import NeonText from "../Atoms/Neon/NeonText";
import {Verified} from "../../Icons/Icon";
import WholePageSpinner from "../Modals/WholePageSpinner";
import DropsOverview from "../Organism/DropsOverview";
import _ from "lodash";
import NeonHeader from "../Atoms/NeonHeader";
import {MotionBox, TRANSITION_ANIMATION} from "../../CONSTANTS/animation_constants";
import {useNavigate} from "react-router-dom";
import {divideAddress, isMobile} from "../../utils/helper-util";
import {useDebounce} from "../../hooks/hooks";
import {useAppDispatch, useAppSelector} from "../../hooks/reducerHooks";
import {positionY, saveNftDropsPositionY} from "../../features/NftDropsSlice/NftDropsSlice";

const NftDropsList = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const dispatch = useAppDispatch();
    const searchRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const pagePositionY = useAppSelector(positionY);
    const { ref, inView } = useInView();
    const [activeInput, setActiveInput] = useState(false)
    const [input, setInput] = useState<string>("");
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const {isSuccess, isError, hasNextPage, fetchNextPage, isFetchingNextPage, data, refetch: refetchAllDrops, isFetching} = useGetAllDrops(input);
    const [hasFetchedFirstTime, setHasFetchedFirstTime] = useState(false)
    const imageRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [collection, setCollection] = useState<any>([]);
    const [isLargerThan640] = useMediaQuery('(min-width: 640px)');
    const [isCellular, setIsCellular] = useState(false);
    const debouncedSearch = useDebounce(input, 500);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: pagePositionY });
        }, 100)
    }, [])

    useEffect(() => {
        if(window.scrollY === 0) return
        dispatch(saveNftDropsPositionY(window.scrollY))
    }, [window.scrollY])


    useEffect(() => {
        setIsCellular(isMobile())
        refetchAllDrops()
        ReactGA.send({ hitType: "pageview", page: "/drops", title: "Nft Drops List" });
    }, [])

    useEffect(() => {
        if(isSuccess && !hasFetchedFirstTime) setHasFetchedFirstTime(true)
    }, [isSuccess])

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView, fetchNextPage, hasNextPage]);

    useEffect(() => {
        if(!_.isEmpty(data)) {
            setCollection(data)
            setIsSearching(false)
        }
    }, [data])

    useEffect(() => {
        if(isError && hasFetchedFirstTime) {
            toast({
                id: "nft-drops-search-error",
                title: "Error!",
                description: "Ooops, fetching drops unsuccessful!",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            setIsSearching(false)
        }
    }, [isError])

    useEffect(() => {
        if(input === "" || !isFetching) return
        setIsSearching(true)
        // @ts-ignore
        refetchAllDrops(input)
    }, [debouncedSearch])

    const handleInput = (text: any) => {
        setInput(text.target.value)
    }

    useOutsideClick({
        // @ts-ignore
        ref: searchRef,
        handler: () => setActiveInput(false),
    })
    return (
       <Box paddingBottom={"20"}>
           <NeonHeader>
               {/*<RadioGroup defaultValue={"ALL"} onChange={(e) => setDropType(e) }>*/}
               {/*    <VStack alignItems={"flex-start"} direction='row'>*/}
               {/*        <LayoutGroup>*/}
               {/*            <Radio size={["sm", "sm", "md", "md"]} color={"white"} value='ALL'><NeonText fontSize={isCellular ? "10px" : ["sm", "md", "md", "md"]} textValue={"ALL"} /></Radio>*/}
               {/*            <Radio size={["sm", "sm", "md", "md"]} color={"white"} value='NFT'><NeonText fontSize={isCellular ? "10px" : ["sm", "md", "md", "md"]} textValue={"NFT"} /></Radio>*/}
               {/*            <Radio size={["sm", "sm", "md", "md"]} color={"white"} value='Notification:%20airdrop'><NeonText fontSize={isCellular ? "10px" : ["sm", "md", "md", "md"]} textValue={"CUSTOM"} /></Radio>*/}
               {/*        </LayoutGroup>*/}
               {/*    </VStack>*/}
               {/*</RadioGroup>*/}
               <MotionBox
                   initial={{ opacity: 0, }}
                   animate={{ opacity: 1, transition: { duration: 0.2, ease: "easeInOut", delay: 0.2}}}
                   exit={{ opacity: 0, transition: TRANSITION_ANIMATION.EXIT }}
                   h={'calc(7vh)'} w={!isLargerThan640 ? `calc(45vw)` : 'calc(45vw)'} borderRadius={20}
                   >
                   <FormControl h={"inherit"} w={"inherit"} isInvalid={false} borderRadius={"inherit"} boxShadow={activeInput ? ["0 0 0.75em #04d9ff"] : ["0 0 0.75em #fff"]} borderColor={"#fff"} >
                       <Input  value={input}
                               boxShadow={["inset 0 0 0.75em #fff"]}
                               borderColor={"white"}
                               _focus={{borderColor: "white", boxShadow: ["inset 0 0 0.75em #04d9ff"]}}
                               onChange={(e) => {
                                   handleInput(e)
                               }}
                               onClick={() => setActiveInput(true)}
                               fontFamily={"Exo2"} _placeholder={{fontWeight: "500", textShadow: "0 0 0.07em #fff" }}
                               fontSize={"xl"} h={"inherit"} w={"inherit"} borderRadius={20}
                               fontStyle={"italic"}
                               variant={"filled"} placeholder={isLargerThan640 ? "Search for specific drop . . ." : "Search..."}/>
                   </FormControl>
               </MotionBox>
               {isSearching ? <Spinner size={"md"} color='cyan.200' position={"absolute"} top={"40%"} right={55}/> : <Box position={"absolute"}/>}
           </NeonHeader>


           {!_.isEmpty(collection.pages) &&
           <TableContainer marginTop={"15vh"} paddingLeft={"2vw"}>
               <Table variant={"unstyled"} size='sm'>
                   <Thead>
                       <Tr>
                           <Th fontFamily={"Exo2"}>Collection</Th>
                           <Th fontFamily={"Exo2"}>Date Added</Th>
                           <Th fontFamily={"Exo2"}>Mint End Date</Th>
                           {/*<Th fontFamily={"Exo2"}>Type</Th>*/}
                       </Tr>
                   </Thead>
                   {isSuccess && _.isEmpty(collection.pages.flat(1)) ? <Box>
                           <NeonText marginLeft={"20%"} marginTop={"20%"} textValue={"Wow, such empty"}/>
                       </Box> :
                   <Tbody>
                       {collection.pages.map((page: any[], z: number) => page.map((drop: { tree: { image: string, collection: { type: string} },
                           mint_start_date: string,
                           mint_end_date: string,
                           created_at: string,
                           name: string, id: string,
                           drop_type: string,
                           description: string, website: string}, i: number) => {
                           const defaultDate = drop?.mint_end_date || "Not Expiring";
                           const name: any = isCellular ? drop.name.split(" ") : drop.name;
                           if(!drop.tree.collection) return
                           return (
                               <Tr key={`${i}${drop.name}`}>
                                   <Td ref={page.length === i + 3 && collection.pages[z].length - 1 > 10  ? ref : null}>
                                       <a href={`/drop/${drop.id}`}>
                                       <HStack>
                                           <DropsOverview
                                               dropRef={imageRef}
                                               presentation={"list"}
                                               showDetails={false}
                                               width={100}
                                               id={i}
                                               drop={drop}
                                           />
                                           <HStack width={"30%"} alignItems={"flex-end"} cursor={"pointer"} onClick={() => navigate(`/drop/${drop.id}`, {
                                               state: {
                                                   dropId: drop.id
                                               },
                                           })
                                           }>
                                               {isCellular ?
                                               <VStack alignItems={"flex-start"}>
                                                   {name.map((n: string) => {
                                                       return <NeonText fontSize={isCellular ? ["sm", "md", "md", "1xl"] : "sm"} marginRight={"2"} textValue={n.includes("0x") ? divideAddress(n) : `${n}`}/>
                                                   })}
                                               </VStack> :
                                                   <NeonText fontSize={isCellular ? ["sm", "md", "md", "1xl"] : "sm"} marginRight={"2"} textValue={`${name}`}/>
                                               }
                                               <Icon as={Verified}/>
                                           </HStack>
                                       </HStack>
                                       </a>
                                   </Td>
                                   <Td>
                                       <NeonText marginRight={"2"} textValue={drop.created_at.split("T")[0]}/>
                                   </Td>
                                   <Td>
                                       <NeonText marginRight={"2"} textValue={`${defaultDate}`}/>
                                   </Td>
                                   {/*<Td><NeonText textValue={drop.tree.collection.type}/></Td>*/}
                               </Tr>
                           )
                       }))
                       }
                   </Tbody>
                   }
               </Table>
               <Box>
                   {isFetchingNextPage && <Spinner
                       bg={"transparent"}
                       thickness='7px'
                       speed='0.65s'
                       size='xl'
                       marginTop={"5"}
                   />}
               </Box>
           </TableContainer>}
           {!hasFetchedFirstTime && !isSuccess && <WholePageSpinner height={"100vh"} width={"100vw"}/>}
       </Box>
    )
};

export default NftDropsList;
