import * as React from "react";
import {
    Box, Button, FormControl, FormErrorMessage, FormLabel, HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Radio, RadioGroup,
    Select, Stack
} from "@chakra-ui/react";
import NeonButton from "../Atoms/NeonButton/NeonButton";
import {useEffect, useState} from "react";

interface SubscribeProps {
    setEmailText: any,
    setAddressText: any,
    subscribe: any,
    loader: any,
    onSaveChain: any,
    error: {
        errorMessage: string,
        addressError: boolean,
        emailError: boolean,
        selectionError: boolean
    }
}
const Subscribe: React.FC<SubscribeProps>  = ({
                                                  setEmailText,
                                                  setAddressText,
                                                  subscribe,
                                                  loader,
                                                  onSaveChain,
                                                  error
                                              }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [emailInput, setEmailInput] = useState("");
    const [addressInput, setAddressInput] = useState("");
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const [value, setValue] = React.useState('')

    const handleEmailInput = (text: any) => {
        setEmailInput(text.target.value)
        setEmailText(text.target.value)
    }

    const handleAddressInput = (text: any) => {
        setAddressInput(text.target.value)
        setAddressText(text.target.value)
    }

    useEffect(() => {
        if(loader === "success") setIsOpen(false)
    }, [loader])

    return (
        <Box>
            <NeonButton fontSize={"ms"} paddingX={"5"} paddingY={"7"} onClick={() => setIsOpen(true)} children={"Subscribe"}/>
            <>
                <Modal
                    initialFocusRef={initialRef}
                    finalFocusRef={finalRef}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                >
                    <ModalOverlay />
                    <ModalContent fontFamily={"Exo2"} borderRadius={20} border={"2px solid rgba(255,255,255, 0.5)"} backgroundColor={"black"}>
                        <ModalHeader>{"You can subscribe as many addresses as you like!"}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl mt={4} isInvalid={error.emailError}>
                                <FormLabel>Email</FormLabel>
                                <Input onChange={(e) => handleEmailInput(e)} value={emailInput} placeholder='e.g. user@gmail.com' />
                                <FormErrorMessage marginLeft={4}>{error.errorMessage}</FormErrorMessage>
                            </FormControl>
                            <FormControl mt={4} isInvalid={error.addressError}>
                                <FormLabel>Address</FormLabel>
                                <Input onChange={(e) => handleAddressInput(e)} value={addressInput} ref={initialRef} placeholder='ETH, ENS, SOL' />
                                <FormErrorMessage marginLeft={4}>{error.errorMessage}</FormErrorMessage>
                            </FormControl>
                            <FormControl mt={4} isInvalid={error.selectionError}>
                                <FormLabel>Select chain</FormLabel>
                                <RadioGroup onChange={(e) => {
                                    setValue(e)
                                    onSaveChain(e)
                                }} value={value}>
                                    <HStack>
                                        <Radio value='Ethereum'>Ethereum</Radio>
                                        <Radio value='Solana'>Solana</Radio>
                                    </HStack>
                                </RadioGroup>
                                <FormErrorMessage marginLeft={4}>{error.errorMessage}</FormErrorMessage>
                            </FormControl>

                            {/*<DropdownSelection data={[{action: "chain", type: "Ethereum"}, {action: "chain", type: "Solana"}]} label={"Select chain"} onSave={(action, type) => onSaveChain(type)}/>*/}
                        </ModalBody>
                        <ModalFooter>
                            <Button isLoading={loader === "loading"} mr={3} onClick={subscribe}>
                                Subscribe
                            </Button>
                            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        </Box>
    )
};

export default Subscribe;
