import React from "react";
import {Box, HStack} from "@chakra-ui/react";

interface StepperProps {
    currentStep: number,
    steps: any
}

const Stepper: React.FC<StepperProps> = ({currentStep, steps}) => {
    const width = window.innerWidth;
    return (
        <HStack height={"5%"} width={"100%"} justifyContent={"space-evenly"} alignItems={"center"}>
            {steps.map((step: any, i: number) => <Box borderRadius={"10"} backgroundColor={currentStep === i + 1 ? "#04d9ff" : "white"} height={"15%"} width={width / steps.length} marginLeft={5} marginRight={5} /> )}
        </HStack>
    )
};

export default Stepper;