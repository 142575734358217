import React, {useEffect, useState} from "react";
import {AbsoluteCenter, Box, Divider, Spinner, Text} from "@chakra-ui/react";
import {useInView} from "react-intersection-observer";
import {useGetAIAnalysis} from "../../services/queries/analysis/useGetAIAnalysis";
import WholePageSpinner from "../Modals/WholePageSpinner";
// import ReactJson from 'react-json-view'
import _ from "lodash";
const AiAnalysis = () => {
    const { ref, inView } = useInView();
    const [content, setContent] = useState<any[]>([])
    const { data, status, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetAIAnalysis()

    useEffect(() => {
        if(data) {
            const cont = data.pages.map(d => d.map((c: any) => {
                console.log("c", c);
                const content = c.conversation[1]
                content.response = c.response
                content.events = c.events
                return content
            }))
            const arrayOfObjects = [].concat(...cont);
            setContent(arrayOfObjects)
        }
    }, [data])

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView, fetchNextPage, hasNextPage]);
     // <pre ref={i === content.length - 1 ? ref : null}>{formattedData}</pre>
    return (
        <Box paddingTop={20}>
            {status === "loading" && <WholePageSpinner height={"100%"} width={"100%"}/>}
            {content.map((c, i) => {
                let parsedEvents = undefined
                const parsedConversation = JSON.parse(c.content);
                const parsedResponse = JSON.parse(c.response);
                // console.log("c.events", c.events);
                if(!_.isEmpty(c.events)) {
                    parsedEvents = c.events.map((event: any) => event)
                }
                // console.log("parsedResponse", parsedResponse);
                // console.log("parsedData", parsedData);*/}
                // const formattedData = JSON.stringify(parsedData, null, 2);
                // console.log("formattedData", formattedData);*/}
                // console.log("parsedData", parsedData);
                return (
                    <Box ref={i === content.length - 1 ? ref : null}>
                        <Box position='relative' padding='10'>
                            <Divider />
                            <AbsoluteCenter fontWeight={"bold"} px='4'>
                                NEW ANALYSIS
                            </AbsoluteCenter>
                        </Box>
                        {/*<ReactJson src={parsedConversation} theme="ashes"/>*/}
                        <Text>Response:<Text fontWeight={"bold"}>{parsedResponse.toUpperCase()}</Text></Text>
                        {parsedResponse === "yes" && parsedEvents &&
                            <Box>
                                <Text>Events:</Text>
                                {/*<ReactJson src={parsedEvents} theme="ashes"/>*/}
                            </Box>}
                    </Box>
                )
            })
            }
            {isFetchingNextPage && <Spinner
                bg={"transparent"}
                thickness='7px'
                speed='0.65s'
                size='xl'
                marginTop={"5"}
            />}
        </Box>
    )
};

export default AiAnalysis;