const validateAddress = (e: any) => {
    let value = e.target ? e.target.value : e
    if(isSolanaAddress(value)) {
        return {
            errorMessage: "",
            error: false
        }
    }
    if(isEthereumAddress(value) || (value.length > 6 && value.includes(".eth"))) {
        return {
            errorMessage: "",
            error: false
        }
    } else {
        return {
            errorMessage: 'Must be ETH or SOL address or be an ENS domain',
            error: true
        }
    }
}

const isSolanaAddress = (address: string) => {
    const solPattern = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/
    return solPattern.test(address)
}

const isEthereumAddress = (address: string) => {
    const ethPattern = /^(0x)?[0-9a-fA-F]{40}$/
    return ethPattern.test(address)
}

const isEmailValid = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email)
}

const validateNumber = (e: any, useInput?: boolean) => {
    const pattern = /^[0-9]+$/g;
    let value = useInput ? e : e.target.value
    if (value.length === 0 || value === "0") return {
        errorMessage: "No value!",
        error: true
    }
    if(pattern.test(value)) return {
        errorMessage: "",
        error: false
    }
    return {
        errorMessage: "Digits only!",
        error: true
    };
}

function containsOnlyNumbers(str: string) {
    return /^\d+$/.test(str);
}

export {
    validateAddress,
    validateNumber,
    containsOnlyNumbers,
    isSolanaAddress,
    isEthereumAddress,
    isEmailValid
}