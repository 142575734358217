import axios from "axios";
import apiUrl from "../api-url";

// const axiosConfig: AxiosRequestConfig = {
//     method: 'post',
//     url: 'https://example.com/api/resource',
//     data: postData,
//     headers: {
//         'Authorization': 'Bearer your-access-token',
//         'Content-Type': 'application/json',
//         'Custom-Header': customHeader
//     }
// };
//
// // Send the request
// axios(axiosConfig)
//     .then((response) => {
//         console.log('Response:', response.data);
//     })
//     .catch((error) => {
//         console.error('Error:', error);
//     });

export const postNftDrop = async (nftDropForm: {}) => {
    // @ts-ignore
    const { data } = await axios.post(`${apiUrl}/nft-drop`, nftDropForm, {
        headers: {
            "Authorization": "ne moze nam niko nista"
        }
    })
    return data;
};

export const getNftDrops = async (page: number) => {
    // @ts-ignore
    const { data, headers } = await axios.get(`${apiUrl}/nft-drop`, {
        params: {
            page_length: 15,
            page
        },
        headers: {
            "Authorization": "ne moze nam niko nista"
        }
    })
    const nfts = {
        data,
        total: headers["x-count-total"],
        one_day: headers["x-count-24h"],
    }
    return nfts
};

export const getAllDrops = async (page: number, name: string) => {
        // @ts-ignore
        const { data } = await axios.get(name.length ? `${apiUrl}/nft-drop?drop_name=${name}` : `${apiUrl}/nft-drop`, {
            params: {
                page_length: 15,
                page
            },
            headers: {
                "Authorization": "ne moze nam niko nista"
            }
        })
        return data;
};

export const getSingleNftDrop = async (id: string) => {
    // @ts-ignore
    const { data } = await axios.get(`${apiUrl}/nft-drop/${id}`, {
        headers: {
            "Authorization": "ne moze nam niko nista"
        }
    })
    return data;
};

export const reportDrop = async () => {
    const { data } = await axios.post(`${apiUrl}/challenge`, {},
        // @ts-ignore
        {
        headers: {
            "Authorization": "ne moze nam niko nista"
        }
    })
    return data;
}

export const reportedDropMessage = async (from: string, to: string, message: string, challenge_id: string, challenge_solution: string, drop_id?: string) => {
    const { data } = await axios.post(`${apiUrl}/message`, {
        from,
        to,
        message,
        challenge_id,
        challenge_solution,
        drop_id,
    },
        // @ts-ignore
        {
            headers: {
                "Authorization": "ne moze nam niko nista"
            }
        }
    )
    return data;
}

