import {useMutation, useQuery, useQueryClient} from "react-query";
import {REPORT_NFT_DROP, REPORTED_NFT_DROP_MESSAGE} from "../../queryConstants";
import {reportDrop, reportedDropMessage} from "../../axios/nftDrops-service";
import {useToast} from "@chakra-ui/react";

export const useReportNftDrop = () => {
    return useMutation([REPORT_NFT_DROP], () => reportDrop());
}
export const useReportDropMessage = (from: string, to: string, message: string, challenge_id: any, challenge_solution: any, drop_id?: string) => {
    const queryClient = useQueryClient();
    const toast = useToast()
    return useMutation([REPORTED_NFT_DROP_MESSAGE], () => reportedDropMessage(from, to, message, challenge_id, challenge_solution, drop_id), {

        onSuccess: async data => {
            toast({
                status: "success",
                title: data.message,
                description: `Thank you for your assistance!`,
                isClosable: true,
            })
                await queryClient.invalidateQueries({
                    queryKey: [REPORTED_NFT_DROP_MESSAGE],
                    // refetchInactive: true
                })
        },
        onError: (e: { response: { data: { message: string}} }) => {
            toast({
                status: `error`,
                title: "Submitting unsuccessful!",
                description: `${e.response.data.message}`,
                duration: 2000,
                isClosable: true,
            })
        },
    });
}


