import * as React from "react";
import {
    Button,
    FormControl, FormLabel, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, useDisclosure
} from "@chakra-ui/react";
import {useEffect, useState} from "react";

interface ModalFormProps {
    isOpen: boolean,
    onClose: any,
    onOpen: any,
    send: any,
    title: string,
    setFrom: any,
    setDropInfo: any,
    setChallResult: any,
    challengeQuestion: string,
    sendBtnLoader: any,
}

const ModalForm: React.FC<ModalFormProps> = ({
                                                 isOpen,
                                                 onClose,
                                                 send,
                                                 title,
                                                 setFrom,
                                                 setDropInfo,
                                                 setChallResult,
                                                 challengeQuestion,
                                                 sendBtnLoader,
}) => {
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const [dropMsg, setDropMsg] = useState("")
    const [fromMsg, setFromMsg] = useState("")
    const [chlngRslt, setChlngRslt] = useState("")

    const handleDrop = (text: any) => {
        setDropMsg(text.target.value)
        setDropInfo(text.target.value)
    }

    const handleFromMsg = (text: any) => {
        setFromMsg(text.target.value)
        setFrom(text.target.value)
    }

    const handleChallengeResult = (text: any) => {
        setChallResult(text.target.value)
        setChlngRslt(text.target.value)
    }

    useEffect(() => {
        if(!isOpen) {
            setDropMsg("")
            setFromMsg("")
            setChlngRslt("")
        }
    }, [isOpen])

    const closeModal = () => {
        setDropMsg("")
        setFromMsg("")
        setChlngRslt("")
        return onClose()
    }

    return (
        <>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={closeModal}
            >
                <ModalOverlay />
                <ModalContent fontFamily={"Exo2"} borderRadius={20} border={"2px solid rgba(255,255,255, 0.5)"} backgroundColor={"black"}>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>From (Optional)</FormLabel>
                            <Input onChange={(e) => handleFromMsg(e)} value={fromMsg} ref={initialRef} placeholder='e.g. Email, Address, Nick' />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Drop Info</FormLabel>
                            <Input onChange={(e) => handleDrop(e)} value={dropMsg} placeholder={"Web URL, Twitter Handle.."}/>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>{challengeQuestion}</FormLabel>
                            <Input onChange={(e) => handleChallengeResult(e)} value={chlngRslt} placeholder='Result' />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button isLoading={sendBtnLoader === "loading"} mr={3} onClick={send}>
                            Send
                        </Button>
                        <Button onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ModalForm;