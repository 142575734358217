import FallingParachute from "../Atoms/FallingParachute/FallingParachute";
import {Box, Center, Spinner} from "@chakra-ui/react";
import * as React from "react";

interface WholePageSpinnerProps {
    showParachute?: boolean,
    height?: string,
    width?: string,
}

const WholePageSpinner: React.FC<WholePageSpinnerProps> = ({showParachute= false, height = undefined, width = undefined}) => {

    return (
        <Center position={"absolute"}
                bg={"transparent"}
                backdropFilter="auto"
                backdropBlur={"sm"}
                top={0}
                left={0}
                h={height || "100%"}
                w={width || "100%"}
                zIndex={10}
        >
            {showParachute &&
                   <Box>
                       <FallingParachute/>
                       <FallingParachute/>
                       <FallingParachute/>
                       <FallingParachute/>
                   </Box>
            }
            <Spinner
                bg={"transparent"}
                thickness='7px'
                speed='0.65s'
                size='xl'
            />
        </Center>
    )
};

export default WholePageSpinner;