// @ts-nocheck
import React, {useEffect, useState} from "react";
import {
    Box,
    FormControl, FormErrorMessage, HStack, Icon,
    Input,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Portal,
    useMediaQuery, useOutsideClick
} from "@chakra-ui/react";
import _ from "lodash";

import {BUTTON_COLORS} from "../../CONSTANTS/color_constants";
import {CHAINS, MotionBox, MotionLink, MotionText, TRANSITION_ANIMATION} from "../../CONSTANTS/animation_constants";

import {divideAddress, isMobile} from "../../utils/helper-util";

import NeonHeader from "../Atoms/NeonHeader";
import NeonButton from "../Atoms/NeonButton/NeonButton";
import NeonText from "../Atoms/Neon/NeonText";
import {isEmailValid, isEthereumAddress, isSolanaAddress, validateAddress} from "../../utils/validation-util";
import Subscribe from "../Molecule/Subscribe";
import {useSubscribeAddress} from "../../services/queries/signUp/useSubscribeAddress";
import {Search2Icon} from "@chakra-ui/icons";

interface AddressOverviewHeaderProps {
    userAddress: string,
    collection: any,
    tree: any[],
    onInputChange(value: any): any,
    onNewAddressInputChange(value: any): any,
    fetchAddress(value: any): any
}

const AddressOverviewHeader: React.FC<AddressOverviewHeaderProps> = ({userAddress, collection, tree, onInputChange, onNewAddressInputChange, fetchAddress}) => {
    const [input, setInput] = useState<string>("");
    const headerAddressRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [headerAddressActiveInput, setHeaderAddressActiveInput] = useState(false)
    const headerNftRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [headerNftActiveInput, setHeaderNftActiveInput] = useState(false)
    const [newAddressInput, setNewAddressInput] = useState<string>("");
    const [addInputField, setAddInputField] = useState(false)
    const [newlyFetchedAddress, setNewlyFetchedAddress] = useState(userAddress)
    const [isLargerThan640] = useMediaQuery('(min-width: 640px)')
    const [filteredCollection, setFilteredCollection] = useState(collection)
    const [isLessThan640] = useMediaQuery('(max-width: 640px)', {ssr: false})
    const [isCellular, setIsCellular] = useState(false)
    const [isError, setIsError] = useState<any>({});
    const [airdropText, setAirdropText] = useState([])
    const [potentialText, setPotentialText] = useState([])
    const [searchAddressActive, setSearchAddressActive] = useState(false)

    //subscription
    const [addressText, setAddressText] = useState("")
    const [emailText, setEmailText] = useState("")
    const [chain, setChain] = useState("")
    const [subscriptionError, setSubscriptionError] = useState({
        errorMessage: "",
        addressError: false,
        emailError: false,
        selectionError: false
    })
    const {mutate: subscribeAddress, status: subscriptionProcessStatus} = useSubscribeAddress(emailText, addressText, chain)



    useEffect(() => {
        setIsCellular(isMobile())
    }, [])

    useEffect(() => {
        if(collection.length > 0) setFilteredCollection(collection)
    }, [collection])

    const handleInputChange = (e:any) => {
        onInputChange(e.target.value)
        setInput(e.target.value)
    }

    const handleNewAddressInputChange = (e: any) => {
        onNewAddressInputChange(e.target.value)
        setNewAddressInput(e.target.value)
        const validation = validateAddress(e)
        setIsError(validation)
    }

    const btn = () => {
        return (
            <NeonButton glowingEffect={false} isLoading={false} fontSize={"ms"} paddingX={"5"} paddingY={"7"} boxShadowColor={BUTTON_COLORS.NEON_BLUE}
                        textShadowColor={BUTTON_COLORS.NEON_RED} onClick={() => setAddInputField(!addInputField)}
                        children={divideAddress(newlyFetchedAddress)} />
        )
    }

    useOutsideClick({
        // @ts-ignore
        ref: headerAddressRef,
        handler: () => setHeaderAddressActiveInput(false),
    })

    useOutsideClick({
        // @ts-ignore
        ref: headerNftRef,
        handler: () => setHeaderNftActiveInput(false),
    })

    const renderInput = () => {
        return (
            <MotionBox
                initial={{ opacity: 0, }}
                animate={{ opacity: 1, transition: { duration: 0.2, ease: "easeInOut", delay: 0.2}}}
                exit={{ opacity: 0, transition: TRANSITION_ANIMATION.EXIT }}
                h={'calc(7vh)'} w={!isLargerThan640 ? `calc(25vw)` : 'calc(30vw)'} borderRadius={20}
                // boxShadow={["0 0 0.75em #fff"]}
            >
                <FormControl h={"inherit"} w={"inherit"} isInvalid={isError.error} borderRadius={"inherit"} boxShadow={headerAddressActiveInput ? isError.error ? ["0 0 0.75em red"] : ["0 0 0.75em #04d9ff"] : ["0 0 0.75em white"]}>
                    <Input  value={newAddressInput}
                            boxShadow={["inset 0 0 0.75em #fff"]}
                            borderColor={"white"}
                            _focus={isError.error ? {borderColor: "white", boxShadow: ["inset 0 0 0.75em red"]} : {borderColor: "white", boxShadow: ["inset 0 0 0.75em #04d9ff"]}}
                            onChange={(e) => {
                                handleNewAddressInputChange(e)
                            }}
                            onKeyPress={(e) => {
                                if(e.code === "Enter" || e.code === "NumpadEnter" ) {
                                    if(!isError.error && newAddressInput !== "") {
                                        // @ts-ignore
                                        fetchAddress(newAddressInput)
                                        setNewlyFetchedAddress(newAddressInput)
                                    }
                                }
                            }}
                            onClick={() => setHeaderAddressActiveInput(true)}
                            fontFamily={"Exo2"} _placeholder={{fontWeight: "500", textShadow: "0 0 0.07em #fff" }}
                            fontSize={isLargerThan640 ? "xl" : "md"} h={"inherit"} w={"inherit"} borderRadius={20}
                            fontStyle={"italic"}
                            variant={"filled"} placeholder={isLargerThan640 ? "Enter address . . ." : "Address..."}/>
                    <FormErrorMessage marginLeft={4}>{isError.errorMessage}</FormErrorMessage>
                </FormControl>
            </MotionBox>
        )
    }

    useEffect(() => {
        const potentialDrops = filteredCollection.reduce((acc: { potential: {usdAmount: number, number: number}, airdrops: {usdAmount: number, number: number} }, curr: {children: any[], floorPriceUsd: any}) => {
            curr.children.map(child => {
                // console.log("child", child);
                if(child.action === "airdrop") {
                    child.children.map((c: any) => {
                        acc.airdrops.usdAmount = acc.airdrops.usdAmount + c.floorPriceUsd
                    })
                    acc.airdrops.number = acc.airdrops.number + 1
                } else {
                    child.children.map((c: any) => {
                        acc.potential.usdAmount = acc.potential.usdAmount + c.floorPriceUsd
                    })
                    acc.potential.number = acc.potential.number + 1
                }
            });
            return acc
        }, {potential: {usdAmount: 0, number: 0}, airdrops: {usdAmount: 0, number: 0}});

        // console.log("potentialDrops", potentialDrops);

        const explanationText = "drops";
        // @ts-ignore
        setAirdropText([`${potentialDrops.airdrops.number} ${explanationText}`, `Claim: $${_.round(potentialDrops.airdrops.usdAmount, 0)}`])
        // @ts-ignore
        setPotentialText([`${potentialDrops.potential.number} potential ${explanationText}`, `Potential: $${_.round(potentialDrops.potential.usdAmount + potentialDrops.airdrops.usdAmount, 0)}`])
    }, [tree, filteredCollection])

    const saveChain = (chain: string) => {
        setChain(chain)
    }
    const checkSubscriptionData = () => {
        const emailValid = isEmailValid(emailText);
        if(!emailValid) return setSubscriptionError({addressError: false, selectionError: false, emailError: true, errorMessage: "Wrong email format",  });
        if(chain === "") return setSubscriptionError({addressError: false,emailError: false, selectionError: true, errorMessage: "Please select chain", })
        if(chain === CHAINS.SOLANA) {
            const checkSolana = isSolanaAddress(addressText)
            if(!checkSolana) return setSubscriptionError({
                selectionError: false, emailError: false,
                addressError: true,
                errorMessage: "Wrong SOL address!",
            })
        }
        if(chain === CHAINS.ETHEREUM) {
            const checkEthereum = isEthereumAddress(addressText)
            if(!(checkEthereum || (addressText.length > 6 && addressText.includes(".eth")))) return setSubscriptionError({
                selectionError: false, emailError: false,
                addressError: true,
                errorMessage: "Wrong ETH address!"
            })
        }
        setSubscriptionError({
            emailError: false,
            addressError: false,
            selectionError: false,
            errorMessage: ""
        })
        subscribeAddress()
    }

    const searchFiled = () => {
        return (
            searchAddressActive ?
                <HStack>
                    <Icon boxSize={["4", "4", "8", "8"]} cursor={"pointer"} onClick={() => setSearchAddressActive(false)} as={Search2Icon}/>
                    <MotionBox
                        initial={{ opacity: 0, }}
                        animate={{ opacity: 1, transition: { duration: 0.2, ease: "easeInOut", delay: 0.2}}}
                        exit={{ opacity: 0, transition: TRANSITION_ANIMATION.EXIT }}
                        h={'calc(7vh)'} w={!isLargerThan640 ? `calc(25vw)` : 'calc(30vw)'} borderRadius={20}
                        // boxShadow={["0 0 0.75em #fff"]}
                    >
                            <FormControl h={"inherit"} w={"inherit"} boxShadow={headerNftActiveInput ? ["0 0 0.75em #04d9ff"] : ["0 0 0.75em #fff"]} isInvalid={false} borderRadius={"inherit"} borderColor={"#fff"} >
                                <Input  value={input}
                                        boxShadow={["inset 0 0 0.75em #fff"]}
                                        borderColor={"white"}
                                        _focus={{borderColor: "white", boxShadow: ["inset 0 0 0.75em #04d9ff"]}}
                                        onChange={(e) => {
                                            handleInputChange(e)
                                        }}
                                        onClick={() => setHeaderNftActiveInput(true)}
                                        fontFamily={"Exo2"} _placeholder={{fontWeight: "500", textShadow: "0 0 0.07em #fff" }}
                                        fontSize={isLargerThan640 ? "xl" : "md"} h={"inherit"} w={"inherit"} borderRadius={20}
                                        fontStyle={"italic"}
                                        variant={"filled"} placeholder={isLargerThan640 ? "Search for your NFTs . . ." : "Search..."}/>
                            </FormControl>
                    </MotionBox>
                </HStack> : <HStack cursor={"pointer"} onClick={() => setSearchAddressActive(true)} >
                    <Icon boxSize={["4", "4", "8", "8"]} as={Search2Icon}/>
                    <NeonText fontSize={["sm", "sm", "md", "xl"]} fontWeight="400" textShadow={"0 0 0.125em #fff"} textValue={"Search..."} />
                </HStack>
        )
    }

    console.log("isCellular", isCellular);

    return (
        <NeonHeader>
            {searchFiled()}
            <Box>
                <Popover trigger={"hover"}>
                    <PopoverTrigger>
                        <Box>
                            {/*<NeonText textShadow={"0 0 0.125em #fff"} fontStyle={"italic"} fontWeight="400" fontSize={["sm","md","1xl","2xl"]} textValue={airdropText[1]}/>*/}
                            <NeonText textShadow={"0 0 0.125em #fff"} fontStyle={"italic"} fontWeight="400" fontSize={["sm", "sm", "md", "xl"]} textValue={potentialText[1]}/>
                        </Box>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent border={"0.5px"} borderColor={"white"} boxShadow={["0 0 0.5em #fff"]} borderRadius={"20"}>
                            <Box borderRadius={"20"} backgroundColor={"black"} boxSize={"inherit"} borderColor={"white"} border={"2px"} padding={"5"} boxShadow={["inset 0 0 0.5em 0 #fff"]}>
                                <NeonText textShadow={"0 0 0.125em #fff"} fontStyle={"italic"} fontWeight="400" fontSize={["sm","md","1xl","2xl"]} textValue={`${airdropText[0]} (drops that are usually one step away)`}/>
                                <NeonText textShadow={"0 0 0.125em #fff"} fontStyle={"italic"} fontWeight="400" fontSize={["sm","md","1xl","2xl"]} textValue={`${potentialText[0]} (drops that require pairing, staking and/or other actions)`}/>
                            </Box>
                        </PopoverContent>
                    </Portal>
                </Popover>
           </Box>
            {addInputField && renderInput()}
            {!isCellular && btn()}
            <Subscribe
                setEmailText={setEmailText}
                setAddressText={setAddressText}
                loader={subscriptionProcessStatus}
                onSaveChain={saveChain}
                subscribe={checkSubscriptionData}
                error={subscriptionError}
            />
        </NeonHeader>
    )
}

export default AddressOverviewHeader