import {
    Box,
    HStack,
    Icon,
    Image,
    Link, Popover, PopoverContent, PopoverTrigger,
    Spinner,
    useDisclosure,
    useMediaQuery,
    VStack, Portal
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {MotionBox} from "../../CONSTANTS/animation_constants";
import placeholder from "../../Images/palceholder.png";
import NeonButton from "../Atoms/NeonButton/NeonButton";
import NeonText from "../Atoms/Neon/NeonText";
import {Verified} from "../../Icons/Icon";
import NeonLink from "../Atoms/Neon/NeonLink";
import PrerequisiteTree from "../Organism/PrerequisiteTree";
import * as React from "react";
import {useGetSingleNftDrop} from "../../services/queries/nftDrops/useGetSingleNftDrop";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import ReactGA from "react-ga4";

import NeonHeader from "../Atoms/NeonHeader";
import {useReportDropMessage, useReportNftDrop} from "../../services/queries/nftDrops/useReportNftDrop";
import ModalForm from "../Modals/ModalForm";
import {DROP_REPORTS} from "../../CONSTANTS/string_constants";
import {checkEventInfo} from "../../utils/helper-util";
import ModalToDonate from "../Modals/ModalToDonate";

const SingleDrop = () => {
    const location = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenDonateModal,
        onOpen: onOpenDonateModal,
        onClose: onCloseDonateModal
    } = useDisclosure()
    const [sentFrom, setSentFrom] = useState("")
    const [dropInfo, setDropInfo] = useState("")
    const [challengeResult, setChallengeResult] = useState("")
    // @ts-ignore
    const dropId = location.state?.dropId || location.pathname.split("drop/")[1];
    const { data, refetch, status } = useGetSingleNftDrop(dropId);
    const { data: reportedData, status: reportedStatus, mutate: mutateReportNftDrop, isLoading: loadingReport} = useReportNftDrop()
    const {mutate: mutatePostMessage, data: messageData, status: messageStatus} = useReportDropMessage(sentFrom, DROP_REPORTS.REPORT_BAD_DROP, dropInfo, reportedData?.challengeId, challengeResult, dropId)
    const [drop, setDrop] = useState({
        tree: {
            image: ""
        },
        name: "",
        approved: false,
        mint_start_date: "",
        mint_end_date: "",
        website: "",
        description: "",
        id: "",
        event_reference: ""
    })

    const defaultDate = "Not Expiring"
    const [isLessThan640] = useMediaQuery('(max-width: 640px)', {ssr: false})

    useEffect(() => {
        if(_.isEmpty(data)) refetch(dropId)
        ReactGA.send({ hitType: "pageview", page: "/drop", title: "Single Drop" });
    }, [])

    useEffect(() => {
        if(!_.isEmpty(data)) setDrop(data)
    }, [data])

    const getChallenge = async () => {
        await mutateReportNftDrop()
    }

    useEffect(() => {
        if(reportedData && reportedStatus === "success") onOpen()
    }, [reportedStatus, reportedData])
    const sendReport = () => {
        mutatePostMessage()
    };

    useEffect(() => {
        if(messageStatus === "success") {
            setDropInfo("")
            setSentFrom("")
            setChallengeResult("")
            return onClose()
        }
    }, [messageStatus])

    const event = checkEventInfo(drop)

    return (
        <Box>
            <NeonHeader>
                <HStack>
                    <NeonText fontStyle={"italic"} fontWeight={"400"} textShadow={"0 0 0.125em #fff"} fontSize={["sm","md","1xl","3xl"]} textValue={drop.name}/>
                    {drop.approved && <Icon as={Verified}/>}
                </HStack>
                <Box/>
            </NeonHeader>
            <HStack alignItems={"flex-end"}>
                <HStack
                    paddingLeft={!isLessThan640 ? ["2rem", "2rem","5rem","10rem"] : "2rem"}
                    paddingTop={"10em"}
                    alignItems={"flex-start"}
                >
                    <VStack>
                        <HStack justifyContent={"space-between"}>
                            <MotionBox
                                layoutId="drop"
                            >
                                <Image borderRadius={"20px"}
                                       boxSize={["150px","200px","250px","350px"]}
                                       fallbackSrc={drop.tree.image || placeholder}
                                       src={drop.tree.image}
                                       marginRight={["0rem","0rem","5rem","5rem"]}
                                />
                            </MotionBox>
                            {isLessThan640 && <VStack alignItems={"flex-start"}>
                                <Popover trigger={"hover"}>
                                    <PopoverTrigger>
                                        <Box>
                                            <NeonButton paddingY={["7", "7","10","10"]} fontSize={["sm", "sm","lg","xl"]} onClick={() => onOpenDonateModal()}>
                                                Donate
                                            </NeonButton>
                                        </Box>
                                    </PopoverTrigger>
                                    <Portal>
                                        <PopoverContent border={"0.5px"} borderColor={"white"} boxShadow={["0 0 1.25em #fff"]} borderRadius={"20"}>
                                            <Box borderRadius={"20"} backgroundColor={"black"} boxSize={"inherit"} borderColor={"white"} border={"2px"} padding={"5"} boxShadow={["inset 0 0 1em 0 #fff"]}>
                                                <NeonText textValue={"If this info helped you in any way, please consider donating. Your donation helps us maintain this site!"} fontSize={"sm"} />
                                            </Box>
                                        </PopoverContent>
                                    </Portal>
                                </Popover>
                                <NeonButton isLoading={reportedStatus === "loading" || loadingReport} paddingY={["7", "7","10","10"]} fontSize={["sm", "sm","lg","xl"]} onClick={() => getChallenge()}>
                                    Report Drop
                                </NeonButton>
                                <NeonButton isLoading={false} paddingY={["7", "7","10","10"]} fontSize={["sm", "sm","lg","2xl"]} disabled={true} onClick={() => console.log('trt')}>
                                    Claim
                                </NeonButton>
                                <NeonButton isLoading={false} paddingY={["7", "7","10","10"]} fontSize={["sm", "sm","lg","2xl"]} disabled={true} onClick={() => console.log('trt')}>
                                    Check Eligibility
                                </NeonButton>
                            </VStack>}
                        </HStack>
                        {isLessThan640 ? <Box
                            justifyContent={"space-around"}
                            whiteSpace={"break-spaces"}
                        >
                            <HStack>
                                <NeonText fontWeight={"200"} textValue={`Name: `}/>
                                <NeonText fontWeight={"500"} textValue={`${drop.name}`}/>
                                {drop.approved && <Icon marginLeft={"2"} as={Verified}/>}
                            </HStack>
                            <HStack>
                                <NeonText fontWeight={"200"} textValue={"Date: "}/>
                                <NeonText fontWeight={"500"} marginRight={"2"} textValue={`${drop?.mint_start_date || "Not determined"}`}/>
                            </HStack>
                            <HStack>
                                <NeonText fontWeight={"200"} textValue={"Expiring date: "}/>
                                <NeonText fontWeight={"500"} marginRight={"2"} textValue={`${drop?.mint_end_date || defaultDate}`}/>
                            </HStack>
                            <HStack>
                                <NeonText fontWeight={"200"} textValue={"Relevant info: "}/>
                                <NeonLink fontWeight={"500"} marginLeft={"2"}
                                          textValue={<Link isExternal href={drop.event_reference}>{`${event}`}</Link>}/>
                            </HStack>

                            <Box width={""}>
                                <NeonText fontWeight={"200"} textValue={"Description: "}/>
                                <NeonText fontWeight={"500"} marginRight={"2"} textValue={`${drop.description}`}/>
                            </Box>
                        </Box> : null}
                    </VStack>
                    {!isLessThan640 ?
                    <Box
                        justifyContent={"space-around"}
                        whiteSpace={"break-spaces"}
                        width={"30vw"}
                    >
                        <HStack>
                            <NeonText fontWeight={"200"} textValue={`Name: `}/>
                            <NeonText fontWeight={"500"} textValue={`${drop.name}`}/>
                            {drop.approved && <Icon marginLeft={"2"} as={Verified}/>}
                        </HStack>
                        <HStack>
                            <NeonText fontWeight={"200"} textValue={"Date: "}/>
                            <NeonText fontWeight={"500"} marginRight={"2"} textValue={`${drop?.mint_start_date || "Not determined"}`}/>
                        </HStack>
                        <HStack>
                            <NeonText fontWeight={"200"} textValue={"Expiring date: "}/>
                            <NeonText fontWeight={"500"} marginRight={"2"} textValue={`${drop?.mint_end_date || defaultDate}`}/>
                        </HStack>
                        <HStack>
                            <NeonText fontWeight={"200"} textValue={"Relevant info: "}/>
                            <NeonLink fontWeight={"500"} marginLeft={"2"}
                                      textValue={<Link isExternal href={drop.event_reference}>{`${event}`}</Link>}/>
                        </HStack>

                        <Box width={""}>
                            <NeonText fontWeight={"200"} textValue={"Description: "}/>
                            <NeonText fontWeight={"500"} marginRight={"2"} textValue={`${drop.description}`}/>
                        </Box>
                    </Box> : null}
                </HStack>
                {!isLessThan640 &&
                    <VStack alignItems={"flex-start"} justifyContent={"space-around"} paddingTop={"40"}>
                        <Popover trigger={"hover"}>
                            <PopoverTrigger>
                                <Box>
                                    <NeonButton paddingY={["7", "7","10","10"]} fontSize={["sm", "sm","lg","xl"]} onClick={() => onOpenDonateModal()}>
                                        Donate
                                    </NeonButton>
                                </Box>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent border={"0.5px"} borderColor={"white"} boxShadow={["0 0 1.25em #fff"]} borderRadius={"20"}>
                                    <Box borderRadius={"20"} backgroundColor={"black"} boxSize={"inherit"} borderColor={"white"} border={"2px"} padding={"5"} boxShadow={["inset 0 0 1em 0 #fff"]}>
                                        <NeonText textValue={"If this info helped you in any way, please consider donating. Your donation helps us maintain this site!"} fontSize={"sm"} />
                                    </Box>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                        <NeonButton isLoading={reportedStatus === "loading" || loadingReport} paddingY={["7", "7","10","10"]} fontSize={["sm", "sm","lg","xl"]} onClick={() => getChallenge()}>
                            Report Drop
                        </NeonButton>
                        <NeonButton isLoading={false} paddingY={["7", "7","10","10"]} fontSize={["sm", "sm","lg","xl"]} disabled={true} onClick={() => console.log('trt')}>
                            Claim
                        </NeonButton>
                        <NeonButton isLoading={false} paddingY={["7", "7","10","10"]} fontSize={["sm", "sm","lg","xl"]} disabled={true} onClick={() => console.log('trt')}>
                            Check Eligibility
                        </NeonButton>
                    </VStack>}
            </HStack>
            <Box marginLeft={["5", "5","25","35"]}>
                {status !== "success" ? <Spinner
                        position={"absolute"}
                        top={"100%"}
                        left={"50%"}
                        bg={"transparent"}
                        thickness='7px'
                        speed='0.65s'
                        size='xl'
                    /> :
                <PrerequisiteTree transform={true} readyForUpdate={["success"].includes(status) && drop.id !== ""} data={drop.tree}/>
                }
            </Box>
            <ModalForm
                title={"Report Bad Drop"}
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                send={sendReport}
                setFrom={setSentFrom}
                setDropInfo={setDropInfo}
                setChallResult={setChallengeResult}
                challengeQuestion={reportedData?.challengeText}
                sendBtnLoader={messageStatus}
            />
            <ModalToDonate
                title={"Thank you!"}
                isOpen={isOpenDonateModal}
                onClose={onCloseDonateModal}
            />
        </Box>
    )
}

export default SingleDrop;