import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import { ethers } from 'ethers'

const MAINNET_RPC_URL = `https://mainnet.infura.io/<INFURA_KEY>`

const injected = injectedModule()

const onboard = Onboard({
    wallets: [injected],
    chains: [
        {
            id: '0x1',
            token: 'ETH',
            label: 'Ethereum Mainnet',
            rpcUrl: MAINNET_RPC_URL
        }
    ],
    accountCenter: {
        desktop: {
            position: 'bottomLeft',
            enabled: true,
            minimal: false
        },
        mobile: {
            position: 'bottomLeft',
            enabled: true,
            minimal: true
        }
    },
    appMetadata: {
        name: "Airdrop Manager",
        description: "All airdrops at one place",
        recommendedInjectedWallets: [
            { name: "Coinbase", url: "https://wallet.coinbase.com/" },
            { name: "MetaMask", url: "https://metamask.io" }
        ]
    }
})

export const connectWallet = async () => {
        const wlts = await onboard.connectWallet();
        if (wlts[0]) {
            // create an ethers provider with the last connected wallet provider
            const ethersProvider = new ethers.providers.Web3Provider(
                wlts[0].provider,
                'any'
            )
            const signer = ethersProvider.getSigner()
            return signer
        }
}