import axios from "axios";
import apiUrl from "../../services/api-url";

export const getHistoricalData = async () => {
    // @ts-ignore
    const { data } = await axios.get(`${apiUrl}/nft-drop-graph`, {
        headers: {
            "Authorization": "ne moze nam niko nista"
        }
    })
    return data;
}