import * as React from "react";
import {useEffect, useState} from "react";
import {
    VStack,
    Box,
    HStack,
    useToast,
} from "@chakra-ui/react";
import _ from "lodash";
import {MotionButton, TRANSITION_ANIMATION} from "../../CONSTANTS/animation_constants";
import customize from "../../Images/customize.png";
import {isPoapAddress} from "../../utils/helper-util";
import NeonText from "../../Components/Atoms/Neon/NeonText";
import NftDropdownInput from "../../Components/Organism/NftDropdownInput";
import PrerequisiteTree from "../../Components/Organism/PrerequisiteTree";
import {usePostNftDropForm} from "../../services/queries/nftDrops/usePostNftDrop";
import FormInput from "../../Components/Molecule/FormInput";
import Stepper from "../../Components/Molecule/Stepper";
import Selection from "../../Components/Molecule/Selection";
import {validateNumber} from "../../utils/validation-util";
import {useAutomaticTyping} from "../../hooks/hooks";
const DropForm = () => {
    const toast = useToast()
    // isError
    const { mutate, isSuccess } = usePostNftDropForm();
    const [tree, setTree] = useState<any>({});
    const [readyForUpdate , setReadyForUpdate] = useState<boolean>(true)
    const [customDropAdded, setCustomDropAdded] = useState(false);
    const [customDrop, setCustomDrop] = useState(false);
    const [dropdownInput, setDropDownInput] = useState("")
    const [dropdownData, setDropdownData] = useState({animate: true, placeholder: "Enter Collection/Coin"})
    const [amountInput, setAmountInput] = useState("")
    const [amountData, setAmountData] = useState({animate: false, placeholder: "Enter amount"});
    const [customInput, setCustomInput] = useState("");
    const [customData, setCustomData] = useState({animate: false, placeholder: "Enter image url"})
    const [graphValue, setGraphValue] = useState<any>({})
    const [showOptions, setShowOptions] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const [showActions, setShowActions] = useState(false)
    const [addNewRequirement, setAddNewRequirement] = useState(false)
    const [additionInfo, setAdditionInfo] = useState({
        name: "",
        mint_end_date: "",
        mint_start_date: "",
        description: "",
        website: "",
        custom_drop: "",
    })
    // const explanationText = useEffect(() => {
    //
    // }, [currentStep])

    const inputFields = [
        {
            btnText: "Next",
            action: () => {
                setCustomInput("")
                setAmountInput("")
                setCustomData({animate: false, placeholder: customData.placeholder})
                setAmountData({animate: false, placeholder: amountData.placeholder})
                setTimeout(() => {
                    setCustomData({animate: true, placeholder: "Website/info url"})
                    setAmountData({animate: true, placeholder: "Description"})
                    setCurrentStep(4)
                    setAdditionInfo({
                        ...additionInfo,
                        mint_start_date: customInput,
                        mint_end_date: amountInput,
                        name: tree.name
                    })
                }, 500)
            }
        },
        {
            btnText: "Submit",
            action: async () => {
                await mutate({
                    ...additionInfo,
                    website: customInput,
                    description: amountInput,
                    tree
                })
            }
        }
    ]

    const steps = [
        {name: "Collection Details", step: 1},
        {name: "Requirements/Options", step: 2},
        {name: "Dates", step: 3},
        {name: "Additional Info", step: 4}
    ];

    const explainText = [
        "Select collection or coin that you wish to add and amount that will be dropped",
        "Add collection that is required in order to receive drop, it can be other collection or coin",
        "Add date when the drop is happening, if it's time sensitive when it's ending as well, this info is not required",
        "Add url where most important info is located, or you can add description yourself, this info is also not required"
    ]
    const actionButtons = [
        {name: "Burn", action: "Burn",},
        {name: "Hold", action: "Hold",},
        {name: "Stake", action: "Stake",}
    ];

    const optionsButtons = [
        {name: "Add new requirement for current Option", action: () => {
                setDropdownData({animate: false, placeholder: dropdownData.placeholder});
                setShowOptions(false);
                setTimeout(() => {setDropdownData({ animate: true, placeholder: "Enter new requirement (collection, coin..)"})}, 500);
                setAddNewRequirement(true)
            }},
        {name: "Add new Option", action: () => {
                setDropdownData({animate: false, placeholder: dropdownData.placeholder});
                setShowOptions(false);
                setTimeout(() => {setDropdownData({ animate: true, placeholder: "Enter new requirement option (collection, coin..)"})}, 500);
            }},
        {name: "Done", action: () => {
                setCurrentStep(3)
                setShowOptions(false);
                setShowOptions(false);
                setCustomData({animate: true, placeholder: "Mint start date (YYYY-MM-DD)"})
                setAmountData({animate: true, placeholder: "Mint end date (YYYY-MM-DD)"})
            }}
    ]

    useEffect(() => {
        if(isSuccess) {
            setTree({})
            setAmountData({animate: false, placeholder: "Enter amount"})
            setCustomData({animate: false, placeholder: "Enter image url"})
            setTimeout(() => {
                setAmountInput("")
                setCustomInput("")
                setDropdownData({animate: true, placeholder: "Enter Collection/Coin"})
                setCurrentStep(1)
                setTree({})
            }, 500)
        }
    }, [isSuccess])

    // Initial creation of drop
    const createInitialTree = () => {
        if(validateNumber(amountInput, true).error) return toast({
            id: "amount-input-error",
            title: "Digits only",
            // description: "Digits only",
            status: 'error',
            duration: 1000,
            isClosable: true,
        })
        setAmountData({animate: false, placeholder: "Enter amount"})
        setDropdownData({animate: false, placeholder: dropdownData.placeholder})
        setCustomData({animate: false, placeholder: customData.placeholder})
        setTree({
            ...graphValue,
            amount: amountInput,
            children: []
        })
        setCustomInput("")
        setTimeout(() => {setDropdownData({ animate: true, placeholder: "Enter requirement (collection, coin..)"})}, 500)
        setDropDownInput("")
        setAmountInput("")
        setCurrentStep(2)
    }
    const addNewBranch = (action: {action: string}) => {
        // console.log("action", action);
        // append prerequisite
        setReadyForUpdate(true)
        if(addNewRequirement) {
            // console.log("GRAF GRAF GRAF", graphValue);
            setTree( (prevState: {children: []}) => ({
                    ...tree,
                    children: prevState.children.map((child: any, i: number) => {
                        if (i === tree.children.length - 1) child.children.push({
                            ...graphValue,
                            action: action.action,
                            amount: amountInput,
                        })
                        console.log("child", child);
                        return {...child}
                    })
                })
            )
            setAddNewRequirement(false)
        } else {
            setTree( (prevState: {children: []}) => ({
                ...tree,
                children: [...prevState.children, {
                    name: `Option ${prevState.children.length + 1}`,
                    children: [{
                        ...graphValue,
                        action: action.action,
                        amount: amountInput,
                    }]
                }]
            }))
        }
        setCustomInput("")
        setAmountInput("")
        setDropDownInput("")
        setShowActions(false)
        setTimeout(() => {setShowOptions(true)}, 200)
    }

    const chooseNewStep = (action: any) => {
        action.action()
    }

    const addDrop = (value: any) => {
        if(customDropAdded && value.customDrop) return toast({
            id: "custom-input-error",
            title: "Custom drop error",
            description: "If drop is custom, no requirement can be custom",
            status: 'error',
            duration: 1000,
            isClosable: true,
        })
        setGraphValue({
            ...value,
            name: value.name || dropdownInput,
            id: value.id || null,
            image: value.image || customize
        })
        if(value.custom_drop && !customDropAdded) return setCustomData({animate: true, placeholder: "Enter image url"})
        if(isPoapAddress({name: value.name, id: value.id})) return setCustomData({animate: true, placeholder: "Enter event ID"})
        addAmountField()
    };

    const addAmountField = () => {
        setTimeout(() => {
            setAmountData({animate: true, placeholder: currentStep !== 1 ? "Enter requirement amount" : "Enter dropped amount"})
        }, 200)
    }

    const showSelection = () => {
        if(validateNumber(amountInput, true).error) return toast({
            id: "amount-input-error",
            title: "Digits only",
            status: 'error',
            duration: 1000,
            isClosable: true,
        })
        setAmountData({animate: false, placeholder: amountData.placeholder})
        setDropdownData({animate: false, placeholder: dropdownData.placeholder})
        setTimeout(() => {setShowActions(true)}, 500)
    }
    const addCustomDrop = () => {
        if(isPoapAddress({name: graphValue.name, id: graphValue.id})) {
            return addPoapDrop()
        }
        // if(!customDropAdded) {
            let image = customInput;
            if(customInput === "" || !customInput.includes("https://")) image = customize
            setCustomDropAdded(true)
            setCustomData({animate: false, placeholder: customData.placeholder})
            setGraphValue({
                    id: null,
                    name: dropdownInput,
                    image: image
                })
            addAmountField()
        // }
    }

    // POAP section
    const addPoapDrop = () => {
        if(checkPoapData()) return poapToast()
        setGraphValue({
            ...graphValue,
            additionalCriteria: {eventId: customInput},
        })
        setCustomData({animate: false, placeholder: customData.placeholder})
        addAmountField()
    }
    const checkPoapData = () => {
        return !_.isInteger(Number(customInput)) || customInput === ""
    }
    const poapToast = () => {
        toast({
            id: "poap-error",
            title: "Error!",
            description: "Wrong Id format!",
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
    }

    console.log("NA KRAJU isSuccess", isSuccess);

    return (
        <Box height={"100vh"}>
            <Stepper currentStep={currentStep} steps={steps}/>
            {/*<Box position={"absolute"}>*/}
            {/*    <AirdropManagerLogo marginLeft={17} marginTop={17}/>*/}
            {/*</Box>*/}
            {/*{form()}*/}
            <HStack height={"95%"} paddingLeft={"10"} justifyContent={"space-between"} alignItems={"flex-start"}>
                <VStack alignItems={"flex-start"} height={"95vh"} justifyContent={"space-between"}>
                    <Box padding={"10"} borderRadius={"20px"} width={"30vw"} maxHeight={"50vh"} border={"0.1rem solid #fff"}>
                        <NeonText textValue={explainText[currentStep - 1]} fontSize={"xl"}/>
                    </Box>
                    {currentStep <= 2 ? <Box>
                            <HStack alignItems={"center"}>
                                <NftDropdownInput
                                    disabled={isSuccess}
                                    marginRight={"0"}
                                    placeholder={dropdownData.placeholder}
                                    onSave={addDrop}
                                    onChange={(e: any) => setDropDownInput(e.target.value)}
                                    search={dropdownInput}
                                    animation={dropdownData.animate}
                                    customDropAdded={customDropAdded}
                                />
                                <FormInput
                                    input={customInput}
                                    placeholder={customData.placeholder}
                                    animation={customData.animate}
                                    onKeyDown={addCustomDrop}
                                    onChange={(e: any) => setCustomInput(e.target.value)}
                                    // left={"40vw"}
                                    // position={"absolute"}
                                />
                                <FormInput
                                    input={amountInput}
                                    placeholder={amountData.placeholder}
                                    animation={amountData.animate}
                                    onKeyDown={currentStep === 1 ? createInitialTree : showSelection}
                                    onChange={(e: any) => setAmountInput(e.target.value)}
                                    // left={"40vw"}
                                    // position={"absolute"}
                                />
                            </HStack>
                            {showActions && <Selection onClick={addNewBranch} buttons={actionButtons}/>}
                            {showOptions && <Selection onClick={chooseNewStep} buttons={optionsButtons}/>}
                        </Box>
                        :
                        <Box>
                            <FormInput
                                input={customInput}
                                placeholder={customData.placeholder}
                                animation={customData.animate}
                                onKeyDown={() => {}}
                                onChange={(e: any) => setCustomInput(e.target.value)}
                            />
                            <FormInput
                                input={amountInput}
                                placeholder={amountData.placeholder}
                                animation={amountData.animate}
                                onKeyDown={() => {}}
                                onChange={(e: any) => setAmountInput(e.target.value)}
                            />
                            <MotionButton
                                initial={{ x: "-10%", opacity: 0, width: 0, zIndex: 0 }}
                                animate={{ opacity: 1, zIndex: 10, width: "30vw", x: 0, transition: { duration: 0.2, ease: "easeInOut", delay: 0.2} }}
                                exit={{ x: "-10%", opacity: 0, width: 0, zIndex: 0, transition: TRANSITION_ANIMATION.EXIT }}

                                key={`Info-btn`}
                                style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                }}
                                variant={"outline"}
                                fontSize={["md","md","xl","xl", "xl"]}
                                color={"white"} height={"10vh"}
                                width={"25vw"} marginTop={5}
                                onClick={() => inputFields[currentStep - 3].action()}
                                borderRadius={"20"}
                                borderWidth={2}
                                borderColor={"white"}
                            >
                                {inputFields[currentStep - 3].btnText}
                            </MotionButton>
                        </Box>
                    }
                </VStack>
                <Box position={"absolute"} left={["10vw","18vw","25vw","25vw"]} top={["10vh","20vh","10vh","10vh"]}>
                    <PrerequisiteTree widthMultiplicator={0.6} transform={false} readyForUpdate={readyForUpdate} data={tree}/>
                </Box>
            </HStack>
        </Box>
    )
}

export default DropForm;