// @ts-nocheck
import React, {useEffect, useMemo, useState} from "react";
import Markdown from 'react-markdown'
import _ from "lodash";

import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box,
    Center,
    HStack,
    Image,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Portal,
    SimpleGrid,
    Spinner, useToast, VStack,
} from "@chakra-ui/react";
import ReactGA from "react-ga4";
import {
    useGetAddressCollection,
} from "../../../services/queries/collections/useGetAddressCollection";
import {useLocation} from "react-router-dom";
import {mapTreeData} from "../../../utils/collectionDataMapping-util";
import AddressOverviewHeader from "../../Organism/AddressOverviewHeader";
import {containsOnlyNumbers, validateAddress} from "../../../utils/validation-util";
import NeonText from "../../Atoms/Neon/NeonText";
import {MotionBox, MotionText, TRANSITION_ANIMATION} from "../../../CONSTANTS/animation_constants";
import placeholder from "../../../Images/palceholder.png";
import NeonButton from "../../Atoms/NeonButton/NeonButton";
import {formatDigits, typeOfDrop} from "../../../utils/formatter";
import NeonSeparator from "../../Atoms/NeonSeparator/NeonSeparator";
import {motion} from "framer-motion";

const AddressNftsOverview = () => {
    const toast = useToast()
    // const navigate = useNavigate();
    const location = useLocation();
    // @ts-ignore
    const [userAddress, setUserAddress] = useState(location.state?.address || location.pathname.split("address-overview/")[1]);
    const [newUserAddress, setNewUserAddress] = useState<string>(location.state?.address || location.pathname.split("address-overview/")[1])
    const [addressQuery, setAddressQuery] = useState<{nftName: null | string, nftNumber: null | number}>({
        nftName: null,
        nftNumber: null,
    })
    const { data: addressCollection, status, hasNextPage, fetchNextPage, refetch, isFetchingNextPage } = useGetAddressCollection(userAddress, addressQuery.nftName, addressQuery.nftNumber)
    const [collection, setCollection] = useState<any>([]);

    useEffect(() => {
        if(_.isEmpty(addressCollection)) refetch(userAddress)
        ReactGA.send({ hitType: "pageview", page: "/address-overview", title: "Address Overview" });
    }, [])

    useEffect(() => {
        if(!_.isEmpty(addressCollection)) setCollection(addressCollection)
    }, [addressCollection])

    const handleInput = (inputValue: string) => {
        const isContainingOnlyNumbers = containsOnlyNumbers(inputValue)
        if(isContainingOnlyNumbers) {
            setAddressQuery({
                nftName: null,
                nftNumber: Number(inputValue)
            })
        } else {
            setAddressQuery({
                nftName: inputValue,
                nftNumber: null
            })
        }
    }

    const handleNewAddressInput = (value: string) => {
        setUserAddress(value);
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            // @ts-ignore
            refetch(userAddress, addressQuery.nftName, addressQuery.nftNumber)
        }, 750)
        return () => {
            clearTimeout(timeout);
        };
    }, [addressQuery])

    const tree = useMemo(() => {
        if(_.isEmpty(collection.pages)) return
        const coll = collection.pages.reduce((acc, curr) => {
            acc = [...acc, ...curr.data]
            return acc
        }, []);
        return mapTreeData(coll)
    }, [collection])

    // const navigateToSingleDrop = (value: string) => {
    //     navigate(`/drop/${value}`, {
    //         state: {
    //             dropId: value
    //         },
    //     })
    // }
    const spinner = () => (
        <Spinner
            top={`50vh`}
            left={"50vw"}
            bg={"transparent"}
            zIndex={10}
            position={"absolute"}
            thickness='7px'
            speed='0.65s'
            size='xl'
        />
    )

    const loadMore = () => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }

    const calculateValue = (drop: any) => {
        const value = drop.children.reduce((acc: { potential: {usdAmount: number, number: number}, airdrops: {usdAmount: number, number: number} }, curr: {children: any[], floorPriceUsd: any}) => {
            // console.log("curr", curr);
            curr.children.map(child => {
                // console.log("child", child);
                if(child.action === "airdrop") {
                    acc.airdrops.usdAmount = acc.airdrops.usdAmount + child.floorPriceUsd
                    acc.airdrops.number = acc.airdrops.number + 1
                } else {
                    acc.potential.usdAmount = acc.potential.usdAmount + child.floorPriceUsd
                    acc.potential.number = acc.potential.number + 1
                }
            });
            return acc
        }, {potential: {usdAmount: 0, number: 0}, airdrops: {usdAmount: 0, number: 0}})
        return value
    }

    // const treePresentation = useMemo(() => {
    //     if(!tree) return
    //     console.log("tree", tree.length);
    //     return (
    //             <VStack marginTop={"40"}>
    //                 {tree.map((branch: {name: string}, i: number) => {
    //                     // console.log("branch", branch);
    //                     return (
    //                         <Box ref={i === tree.length - 3 ? ref : null} key={`${branch.name} ${i}`}>
    //                             <Box>
    //                                 <AirdropTree onNodeClick={navigateToSingleDrop} key={`${i}`} data={branch}/>
    //                             </Box>
    //                             <Divider borderWidth={3}/>
    //                         </Box>
    //                     )
    //                 })}
    //             </VStack>
    //     )
    // }, [tree])


    const treePresentation = useMemo(() => {
        if(!tree) return
        return (
                <SimpleGrid columns={[2,2,4,5]} spacing={20} marginLeft={"20"} marginRight={"20"} marginTop={"40"} marginBottom={"20"}>
                    {tree.map((branch: {id: string, name: string, image: string, number: string, children: any, description: string}, i: number) => {
                        // console.log("branch", branch, branch.image);
                        const dropValues = calculateValue(branch)
                        const nftNumber = branch.number.length > 10 ? branch.number.substring(0, 10) + "\u2026" : branch.number
                        return (
                                <Popover placement={"right"} trigger={"hover"}>
                                    <PopoverTrigger>
                                        <Box
                                            // borderRadius={"20px"}
                                            position={"relative"}
                                            cursor={"pointer"}
                                            // overflow={"hidden"}
                                            // boxShadow={["0 0 0.75em #fff"]}
                                            // borderWidth={1}
                                            // borderColor={"white"}
                                            // borderRightWidth={3}
                                            // borderBottomWidth={3}
                                            // _hover={{borderWidth: 1, borderRightWidth: 5, borderBottomWidth: 5}}
                                            // onClick={() => {
                                            //     navigateToSingleDrop(branch.id)
                                            // }}
                                        >
                                            <Image
                                                // overflow={"hidden"}
                                                borderRadius={"20px"}
                                                boxSize={["8rem","10rem", "12rem", "15rem"]}
                                                borderWidth={1}
                                                borderRightWidth={3}
                                                borderBottomWidth={3}
                                                boxShadow={["0 0 0.75em #fff"]}
                                                fallbackSrc={placeholder}
                                                src={branch.image}
                                                marginRight={"0rem"}
                                            />
                                            <Box>
                                                <MotionBox
                                                    position={"absolute"} top={"5"} right={"5"}
                                                    overflow={"hidden"}
                                                    borderRadius={"8"}
                                                    padding={"1"}
                                                    backgroundColor={'rgba(0, 0, 0, 0.5)'}
                                                >
                                                    <NeonText textValue={`$${formatDigits(dropValues.airdrops.usdAmount + dropValues.potential.usdAmount, false, 0)}`}/>
                                                </MotionBox>
                                                <MotionBox
                                                    position={"absolute"} bottom={"0"}
                                                    height={"22%"}
                                                    width={"100%"}
                                                    borderBottomRadius={"20px"}
                                                    overflow={"hidden"}
                                                    backgroundColor={'rgba(0, 0, 0, 0.5)'}
                                                >
                                                    <Box display={"flex"} flexDirection={"column"}>
                                                        <NeonText marginTop={"1"} marginLeft={"3"} textValue={`${branch.name} ${nftNumber}`}/>
                                                    </Box>
                                                </MotionBox>
                                            </Box>
                                        </Box>
                                    </PopoverTrigger>
                                    <Portal>
                                        <PopoverContent width={"100%"} border={"0.5px"} borderColor={"white"} boxShadow={["0 0 0.5em #fff"]} borderRadius={"20"}>
                                            <Box borderRadius={"20"} backgroundColor={"black"} boxSize={"inherit"} borderColor={"white"} border={"2px"} padding={"5"} boxShadow={["inset 0 0 0.5em 0 #fff"]}>
                                                <NeonSeparator paddingTop={"0"} paddingBottom={"1rem"} name={` Based on holding ${branch.name} ${nftNumber} you can:`}/>
                                                {branch.children.map((child: any) => {
                                                    // console.log("child.action", child.action);
                                                    return (
                                                            <HStack justifyContent={"space-between"}>
                                                                <Popover placement={"right"} trigger={"hover"}>
                                                                      <PopoverTrigger>
                                                                        <HStack marginRight={"1rem"} _hover={{backgroundColor: 'rgba(255,255,255, 0.1)', borderRadius: "20px"}} padding={2} width={"50%"}  alignItems={"center"}>
                                                                            {/* <a href={`/drop/${child.nftDropId}`} target="_blank"/> */}
                                                                            <Image src={child.image} fallbackSrc={placeholder} borderRadius={"20px"} boxSize={["2rem","2.5rem", "3rem", "3.75rem"]} />
                                                                            <NeonText maxW={"50%"} fontSize={"sm"} textValue={typeOfDrop(child)}/>
                                                                            <NeonText maxW={"50%"} fontSize={"sm"} textValue={`&`}/>
                                                                        </HStack>
                                                                      </PopoverTrigger>
                                                                        <Portal>
                                                                            <Box
                                                                                sx={{
                                                                                    "& .chakra-popover__popper": {
                                                                                        zIndex: "popover"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <PopoverContent zIndex={10} borderRadius={"20"} backgroundColor={"black"}
                                                                                                borderColor={"white"}
                                                                                                border={"2px"} padding={"5"} boxShadow={["inset 0 0 0.5em 0 #fff"]}>
                                                                                    <Box fontFamily={"Exo2"}>
                                                                                        <Markdown>{child.description}</Markdown>
                                                                                    </Box>
                                                                                </PopoverContent>
                                                                            </Box>
                                                                        </Portal>
                                                                    </Popover>
                                                                {child.children.map((c: any) => {
                                                                    return (
                                                                        <Popover placement={"right"} trigger={"hover"}>
                                                                            <PopoverTrigger>
                                                                                <HStack _hover={{backgroundColor: 'rgba(255,255,255, 0.1)', borderRadius: "20px"}} padding={2} cursor={"pointer"} width={"100%"}
                                                                                        alignItems={"center"} justifyContent={"space-between"}>
                                                                                    <a href={`/drop/${c.nftDropId}`} target="_blank">
                                                                                        <HStack justifyContent={"space-between"} alignItems={"center"}>
                                                                                            <NeonText fontSize={"sm"} textValue={"Get "}/>
                                                                                            <Image src={c.image} fallbackSrc={placeholder} borderRadius={"20px"}
                                                                                                   boxSize={["2rem", "2.5rem", "3rem", "3.75rem"]}/>
                                                                                            <NeonText fontSize={"sm"} textValue={`${c.name}`}/>
                                                                                        </HStack>
                                                                                    </a>
                                                                                    <NeonText textValue={`$${formatDigits(c.floorPriceUsd, false, 0)}`}/>
                                                                                </HStack>
                                                                            </PopoverTrigger>
                                                                            <Portal>
                                                                                <Box
                                                                                    sx={{
                                                                                        "& .chakra-popover__popper": {
                                                                                            zIndex: "popover"
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <PopoverContent zIndex={10} borderRadius={"20"} backgroundColor={"black"}
                                                                                                    borderColor={"white"}
                                                                                                    border={"2px"} padding={"5"} boxShadow={["inset 0 0 0.5em 0 #fff"]}>
                                                                                        <Box fontFamily={"Exo2"}>
                                                                                            <Markdown>{c.description}</Markdown>
                                                                                        </Box>
                                                                                    </PopoverContent>
                                                                                </Box>
                                                                            </Portal>
                                                                        </Popover>
                                                                    )}
                                                                )}
                                                            </HStack>
                                                    )}
                                                )}
                                                    <Accordion marginTop={"2"}>
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box fontFamily={"Exo2"} as="span" flex='1' textAlign='left'>
                                                                        Details
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                <Box fontFamily={"Exo2"} width={["300px","300px","500px","500px"]}>
                                                                    <Markdown>{branch.description}</Markdown>
                                                                </Box>
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    </Accordion>
                                            </Box>
                                        </PopoverContent>
                                    </Portal>
                                </Popover>
                        )
                    })}
                </SimpleGrid>
        )
    }, [tree])

    return (
            <Center>
                {tree &&
                    <AddressOverviewHeader onInputChange={(e: any) => handleInput(e)} tree={tree} onNewAddressInputChange={(e: any) => handleNewAddressInput(e)}
                                       collection={tree} userAddress={newUserAddress} fetchAddress={(e: any) => refetch(e)}/>
                }
                {!addressCollection && status === "loading" ? spinner() :
                    <VStack>
                        {treePresentation}
                        <NeonButton borderRadius={"10"} marginTop={"5"} marginBottom={"5"} isLoading={isFetchingNextPage} border={"0.1rem solid #fff"} paddingY={"3"} paddingX={"5"} disabled={!hasNextPage} fontSize={"sm"} onClick={() => loadMore()}  children={"Load More"}/>
                        <MotionText as={motion.div}  marginX={"30"}
                                    initial={{ opacity: 0, }}
                                    animate={{ opacity: 1, transition: { duration: 0.5, ease: "easeInOut", delay: 1.2}}}
                                    exit={{ opacity: 0, transition: TRANSITION_ANIMATION.EXIT }}
                                    fontSize={["sm", "sm", "md", "xl"]} fontWeight="400" textShadow={"0 0 0.125em #fff"} fontFamily={"Exo2"}
                                    marginTop={["5","5","5","5"]} marginBottom={"10"}>Tired of checking addresses? Subscribe!!</MotionText>
                    </VStack>
                }
            </Center>
            )
    }
    export default AddressNftsOverview;