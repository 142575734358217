import * as React from "react";
import {Center, VStack} from "@chakra-ui/react";
import NeonText from "../Atoms/Neon/NeonText";
import {AirdropManagerLogo, CircleCheckmark} from "../../Icons/Icon";

const EmailConfirmation = () => {

    return (
        <Center>
            <VStack marginTop={10}>
                <NeonText fontSize={["2xl","2xl","3xl","3xl"]} textValue={"Subscription confirmed successfully"}/>
                <NeonText marginBottom={"20"} fontSize={["l","l","xl","xl"]} textValue={"You will now receive alerts"}/>
                <CircleCheckmark/>
                <VStack marginTop={"20"}>
                    <AirdropManagerLogo/>
                    <NeonText fontSize={["md","md","l","l"]} textValue={"Your place for every NFT airdrop"}/>
                </VStack>
            </VStack>
        </Center>
    )
}

export default EmailConfirmation;