import {Flex} from "@chakra-ui/react";
import * as React from "react";
import AirdropManagerLogo from "./AirdropManagerLogo";

interface NeonHeaderProps {
    children?: JSX.Element | JSX.Element[];
    borderBottomLeftRadius?: number,
    borderBottomRightRadius?: number,
    boxShadow?: string,
}

const NeonHeader: React.FC<NeonHeaderProps> = ({children, boxShadow = "0px 0px 20px #666666", borderBottomLeftRadius = 20, borderBottomRightRadius = 20 }) => {

    return (
        <Flex zIndex={1}
              boxShadow={boxShadow}
              borderBottomLeftRadius={borderBottomLeftRadius}
              borderBottomRightRadius={borderBottomRightRadius}
              bg={"black"} as={"header"} padding={"5"} position={"fixed"} top={0}
              justifyContent={"space-between"}
              h={"12.5vh"} w={"100vw"}
              alignItems={"center"}
        >
            <AirdropManagerLogo/>
            {children}
        </Flex>
        )
};


export default NeonHeader;