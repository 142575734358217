import {useInfiniteQuery} from 'react-query';
import {getAddressCollection} from "../../axios/collections-service";
import {GET_ADDRESS_COLLECTION} from "../../queryConstants";
import {useToast} from '@chakra-ui/react'
// when possible default values are checked, we can unify all these into one

export const useGetAddressCollection = (address: string, name: string | null, number: number | null) => {
    const toast = useToast()
    let counter = 0;

    return useInfiniteQuery([GET_ADDRESS_COLLECTION, address, `${name}20`, number], ({pageParam = 1}) => getAddressCollection(pageParam, address, name, number), {
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.length === 10 ? allPages.length + 1 : undefined;
        },
        onSuccess: (response) => {
            // console.log("onSuccess response", response);
            if(response.pages[response.pages.length - 1].headers["x-pulling-status-done"] === "false") {
                const pulled = response.pages[response.pages.length - 1].headers["x-nfts-pulled"]
                return toast.update("collection-data", {
                    title: "We have some data",
                    description: `Fetched ${pulled}. There is more to be fetched!`,
                    status: "info",
                    isClosable: false,
                    duration: 5000,
                })
            }
            toast.close("collection-data")
        },
        onError: (err: any) => {
            // console.log("onError", err);
            counter = 0;
            if(err.response.headers["x-pulling-status-done"] === "true" && err.response.status === 404) {
                return toast.update("collection-data",{
                    title: "Sorry",
                    description: "No drops found for your NFTs at the moment, try again later!",
                    status: "info",
                    isClosable: true,
                    duration: 5000,
                })
            }
            if(err.response.data.message === "Invalid address") {
                return toast.update("collection-data", {
                    title: err.response.data.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
                toast.update("collection-data", {
                    title: "Oops, something went wrong",
                    description: "Please try this address again later!",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
        },
        retry: (failureCount, error) => {
            counter++
            // console.log("RETRY, err", error);
            if(counter > 1) {
                    toast.update("collection-data", {
                        title: "No drops for now",
                        description: "We're fetching you data!",
                        status: "loading",
                        isClosable: false,
                        duration: null,
                    })
                } else {
                    toast({
                        id: "collection-data",
                        title: "No drops for now",
                        description: "We're fetching you data!",
                        status: "loading",
                        isClosable: false,
                        duration: null,
                    });
                }
                return error.response.headers["x-pulling-status-done"] === "false" && error.response.status === 404;
        },
        retryDelay: 10000,
        staleTime: Infinity,
        enabled: false,
    });
};


