import {useMutation, useQueryClient} from 'react-query';
import { postNftDrop } from "../../axios/nftDrops-service";
import { useToast } from '@chakra-ui/react'
import {GET_COIN_LIST, GET_COLLECTION_BY_NAME, POST_NFT_DROP_FORM} from "../../queryConstants";

export const usePostNftDropForm = () => {
    const queryClient = useQueryClient();
    const toast = useToast()
    return useMutation((data: {}) => postNftDrop(data), {
        mutationKey: POST_NFT_DROP_FORM,
        onSuccess: data => {
            toast({
                status: "success",
                title: "Form successfully submitted!",
                description: `Checking validity of your post.`
            })
            queryClient.invalidateQueries(GET_COIN_LIST)
            queryClient.invalidateQueries(GET_COLLECTION_BY_NAME)
        },
        onError: (e: { response: { data: { message: string}} }) => {
            toast({
                status: `error`,
                title: "Submitting unsuccessful!",
                description: `${e.response.data.message}`,
                duration: 2000,
                isClosable: true
            })
        },
    });
}

