import React from "react";
import {Box, Button, VStack} from "@chakra-ui/react";
import NeonText from "../Atoms/Neon/NeonText";
import {MotionBox} from "../../CONSTANTS/animation_constants";
import {useAutomaticTyping} from "../../hooks/hooks";

interface SelectionProps {
    onClick({}): any,
    buttons: any,
}

const Selection: React.FC<SelectionProps> = ({onClick, buttons}) => {
    const explanationText = useAutomaticTyping( 10, buttons[0].name === "Burn" ? "Please select how it's going to be utilized" : "Please choose next step")
    return (
        <Box marginBottom={5} bottom={0} position={"absolute"}>
            <NeonText textValue={explanationText} />
            <VStack alignItems={"flex-start"}>
                {buttons.map((btn: any, i: number) =>
                    <MotionBox
                        key={`selection-motion-${i}`}
                        initial={{ x: "-10%", opacity: 0}}
                        animate={{opacity: 1, x: 0, transition: {duration: 0.2, ease: "easeInOut", delay: 0.1 * i}}}
                        exit={{ x: "-10%", opacity: 0, transition: {duration: 0.2, ease: "easeInOut", delay: 0.1 * i}}}
                    >
                        <Button key={`selection-btn-${i}`} style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                        }} variant={"outline"} fontSize={["sm","sm","sm","l","xl"]} color={"white"} height={"10vh"} width={"30vw"} marginTop={5} onClick={() => onClick(buttons[i])} borderRadius={"20"} borderWidth={2} borderColor={"white"}>
                            {btn.name}
                        </Button>
                    </MotionBox>
                )}
            </VStack>
        </Box>
    )
};

export default Selection;