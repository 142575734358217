import * as React from "react";
import {Link, Text} from "@chakra-ui/react";

interface NeonLinkProps {
    textValue: string | JSX.Element,
    fontSize?: string | string[],
    // fontWeight: string,
    // textShadow: string,
    marginBottom?: string,
    marginLeft?: string,
    textShadow?: string,
    marginTop?: string,
    fontWeight? :string,
    marginRight?: string
}

const NeonLink: React.FC<NeonLinkProps> = ({textValue, textShadow = "0 0 0em #fff",marginTop= "0",fontWeight = "600", marginBottom= "0",marginRight = "0", fontSize = "md", marginLeft ="0"})  => {

    return (
        <Text
            marginRight={marginRight}
            marginLeft={marginLeft}
            marginTop={marginTop}
            marginBottom={marginBottom}
            fontWeight={fontWeight}
            fontFamily={"Exo2"}
            textShadow={textShadow}
            fontSize={fontSize}
        >
            {textValue}
        </Text>
    )
};

export default NeonLink;