import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import {styles} from "./globalStyles";
import FormStyles from "./components/FormStyles";
import InputStyles from "./components/InputStyles";

const config: ThemeConfig = {
    initialColorMode: `dark`,
    useSystemColorMode: false,
};

const t = {
    config,
    styles,
    components: {
        Form: FormStyles,
        Tabs: {
            variants: {
                'line': {
                    tab: {
                        color: "white",
                        borderColor: "white",
                        _selected: {
                            borderColor: 'cyan.300',
                            color: "cyan.300",
                            // bg: "yellow.100",
                        }
                    }
                }
            },
        }
        // Input: InputStyles,
    },
    shadows: { outline: '0 0 0 2px green' },
}

const theme = extendTheme(t);

export default theme;