import _ from "lodash";
import parachute from "../Images/parachute.png"
import {isPoapAddress} from "./helper-util";
import {isSolanaAddress} from "./validation-util";

// remove unnecessary data
type nftObject = {
    nft_content_url: string,
    nft_number: number,
    nft_short_name: string,
    image: string,
    name: string,
    collection_id: string,
    address: string,
    collection: {
        description: string,
        collection_image: string,
        stats: {
            floor_price: string,
            floor_price_usd: number,
        },
    },
    drops: [{
        name: string,
        id: string,
        contract_address: string,
        image: string,
        nft_drop_id: string,
        collection: {
            stats: {
                floor_price: number,
                floor_price_usd: number,
            },
            type: string,
            mint_begin_date: string,
            mint_end_date: string,
            mint_price: string,
            collection_image: string,
            description: string,
            website: string,
            opensea: string,
            contract_address: string,
        },
        children: [{
            parentId: string,
            name: string,
            description: string,
            children: [{
                id: string,
                action: string,
                name: string,
                length: number,
                image: string,
                collection: {
                    collection_id: string,
                    collection_image: string,
                    image: string,
                    id: string,
                    description: string,
                    name: string,
                    contract_address: string,
                    stats: {
                        floor_price: string,
                        floor_price_usd: number,
                    }
                }
            }]
        }]
    }],
};

// const iterate = async (obj: any) => {
//     obj.name = obj.id
//     for (key of Object.keys(obj)) {
//         // console.log(`key: ${key}, value: ${obj[key]}`)
//
//         // example how to delete a property
//         if (key == 'parentid') {
//             delete obj[key]
//         }
//
//         // recursively enter subobjects
//         if (typeof obj[key] === 'object' && obj[key] !== null) {
//             iterate(obj[key])
//         }
//     }
// }
// []

const mapTreeData = (c: any) => {
    // console.log("c", c);
    let nftAirDropArray: any = []
    c.map((nft: nftObject, i: number) => {
        const isPoap = isPoapAddress({name: nft.nft_short_name, id: nft.collection_id})
        let nftData: any = {}
        const nftImage = nft.nft_content_url ?? nft.collection.collection_image
        // console.log("nft", nft);
        nftData[i] = {
            name: nft.nft_short_name,
            number: nft.nft_number,
            id: nft.collection_id,
            image: isPoap ? nft.image : nftImage,
            description: nft.collection.description,
            floorPriceUsd: nft.collection.stats.floor_price_usd,
            floorPrice: nft.collection.stats.floor_price,
            children: []
        }
        nft.drops.map((drop, n) => {
            // console.log("drop", drop);
            drop.children.map((version, m) => {
                const prerequisiteData: any = []
                version.children.map((prerequisite, z) => {
                    let action = "hold";
                    if(version.children[z].action === "burn") {
                        action = "burn"
                    }
                    // console.log("prerequisite", prerequisite);
                    // console.log("drop", drop);
                    const isAirdrop = nftData[i].id === prerequisite.collection?.contract_address
                    const isMoney = prerequisite.action !== "hold" && prerequisite.action !== "burn";
                    const image = isMoney ? prerequisite.image : prerequisite.collection.collection_image
                    if(isMoney) action = prerequisite.action
                    const dropImage = isPoap ? drop.image : image
                    // console.log(drop.name, drop.collection?.stats.floor_price_usd, prerequisite.collection?.stats.floor_price_usd);
                    const data = {
                        action: isAirdrop ? "airdrop" : action,
                        children: [{
                            name: drop.name,
                            id: drop.collection?.contract_address,
                            image: isPoap? dropImage: drop.collection?.collection_image,
                            description: drop.collection?.description,
                            nftDropId: drop.nft_drop_id,
                            floorPriceUsd: drop.collection?.stats.floor_price_usd,
                            floorPrice: drop.collection?.stats.floor_price
                        }],
                        description: prerequisite.collection?.description,
                        floorPrice: prerequisite.collection?.stats.floor_price,
                        floorPriceUsd: prerequisite.collection?.stats.floor_price_usd,
                        id: prerequisite.id,
                        image: isAirdrop ? parachute : dropImage,
                        name: isAirdrop ? "" : prerequisite.name,
                    }
                    // @ts-ignore
                    prerequisiteData.push(data)
                });
                const prerequisite = prerequisiteData.filter((p: any, l: number) => {
                    if(isPoap && prerequisiteData.length > 1 && prerequisiteData.length - 1 !== l) return
                    if(!prerequisiteData.find((pre: any) => pre.id === nftData[i].id)) return
                    if(prerequisiteData.length > 1 && p.id === nftData[i].id && !isPoap) return
                    return p
                })
                prerequisite.map((p: any) => nftData[i].children.push(p))
            });
        })
        nftAirDropArray.push(nftData[i])
    })
    return nftAirDropArray
}


export {
    mapTreeData
}