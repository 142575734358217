function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
}

function divideAddress(str: string) {
    if(!str) return ""
    return `${str.substring(0,4)}...${str.substring(str.length-4)}`
}

// web3modal helpers
export const truncateAddress = (address: string) => {
    if (!address) return "No Account";
    const match = address.match(
        /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
    );
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
};

export const toHex = (num: any) => {
    const val = Number(num);
    return "0x" + val.toString(16);
};

export const isPoapAddress = (value: {name: string, id: string}) => {
    const poapAddress = "0x22c1f6050e56d2876009903609a2cc3fef83b415"
    return value.name === "POAP" && value.id.includes(poapAddress)
};

// function isNumeric(str: string) {
//     if (typeof str != "string") return false // we only process strings!
//     return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
//         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
// }

export const getMaxValue = (arr: any) => {
    let maxValue = -Infinity;
    let value
    for (let i = 0; i < arr.length; i++) {
        if(arr[i].stats === null) {
            value = 1
        } else {
            value = arr[i].stats.windowCollectionStats?.volume.eth;
        }
        if (value > maxValue) {
            maxValue = value;
        }
    }
    return maxValue;
}
// fp: number, volumeChange: number
export const filteredDefaultNodesAndLinks = (nodes: any[], links: any[], volume: number) => {
    const filteredNodes = nodes.filter(node => {
        if(node.stats === null) return
        return Number(node.stats.windowCollectionStats.volume.eth) > volume
    })
    const nodeIds = filteredNodes.map((node: any) => node.id);
    const filteredLinksPerVolume = links.filter((links: any) => {
        return nodeIds.includes(links.additional_data.id)
    });

    return {nodes: filteredNodes, links: filteredLinksPerVolume}
}

export const biggestAndLowestVolume = (array: any[]) => {
    let volumeRange;
    // console.log("array", array);
    array.reduce((result: any, volume: any) => {
        if(volume.stats === null) return
        if (Number(volume.stats.windowCollectionStats.volume.eth) > result.biggestVolume) {
            result.biggestVolume = Number(volume.stats.windowCollectionStats.volume.eth);
        }
        volumeRange = [result.lowestVolume, result.biggestVolume]
        return result;
    }, { biggestVolume: 0, lowestVolume: 0 });
    return volumeRange
}

let details = navigator.userAgent;

/* Creating a regular expression
containing some mobile devices keywords
to search it in details string*/

const isMobile = () => {
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);

    if (isMobileDevice) {
        // console.log("You are using a Mobile Device");
        return true
    } else {
        // console.log("You are using Desktop");
        return false
    }
}
const isSafari = () => {
// Check if the user's browser is Safari
    if (navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome")) {
        return true;
    } else {
        return false;
    }
}

const checkEventInfo = (eventData: { event_reference: string, website: string }) => {
    if (!eventData.event_reference && !eventData.website) return "No Data"
    if (!eventData.event_reference) return eventData.website
    if (eventData.event_reference.includes("discord.com")) return "discord channel"
    if (eventData.event_reference.includes("twitter.com")) {
        const twitterString = "https://twitter.com/";
        const twitterHandle = eventData.event_reference.split(twitterString);
        if(twitterHandle.length === 1) return twitterHandle
        const handle = twitterHandle[1].split("/")
        return `twitter/${handle[0]}`
    } else if (eventData.event_reference.includes("x.com")) {
        const xString = "https://x.com/";
        const xHandle = eventData.event_reference.split(xString);
        if(xHandle.length === 1) return xHandle
        const handle = xHandle[1].split("/")
        return `x/${handle[0]}`
    }
    return eventData.event_reference
}



export { isEmpty, divideAddress, isMobile, isSafari, checkEventInfo }