import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import {AnimatePresence} from "framer-motion";

import AirDropManagerLanding from "../Screens/AirDropManagerLanding/AirDropManagerLanding";
import AddressNftsOverview from "../Screens/AirDropManagerOverview/AddressNftsOverview";
import DropForm from "../../features/DropForm/DropForm";
import NftDropsList from "../Screens/NftDropsList";
import SingleDrop from "../Screens/SingleDrop";
import Historiograph from "../../features/Historiograph/Historiograph";
import AiAnalysis from "../Screens/AiAnalysis";
import EmailConfirmation from "../Screens/EmailConfirmation";
import EmailConfirmationError from "../Screens/EmailConfirmationError";
import EmailRemovalError from "../Screens/EmailRemovalError";
import EmailRemoval from "../Screens/EmailRemoval";

const AnimatedRoutes = () => {
    const location = useLocation();
    return (
        // initial={false}
        <AnimatePresence exitBeforeEnter>
            <Routes location={location} key={location.pathname}>
                <Route path={"/"} element={<AirDropManagerLanding/>}/>
                <Route path={"/address-overview/:address"} element={<AddressNftsOverview/>}/>
                <Route path={"/form"} element={<DropForm/>}/>
                <Route path={"/historiograph"} element={<Historiograph/>}/>
                <Route path={"/drops"} element={<NftDropsList/>}/>
                <Route path={"/drop/:id"} element={<SingleDrop/>}/>
                <Route path={"/analysis"} element={<AiAnalysis/>}/>
                <Route path={"/email/subscription/confirmation"} element={<EmailConfirmation/>}/>
                <Route path={"/email/subscription/removal"} element={<EmailRemoval/>}/>
                <Route path={"/email/subscription/confirmation-error"} element={<EmailConfirmationError/>}/>
                <Route path={"/email/subscription/removal-error"} element={<EmailRemovalError/>}/>
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes