// @ts-nocheck
// add k up
import * as React from "react";
import { Divide as Hamburger } from 'hamburger-react'
import {useEffect, useMemo, useRef, useState} from "react";
import {
    Flex,
    Center,
    Input,
    useToast,
    FormControl,
    FormErrorMessage,
    Box,
    Stack,
    Icon,
    HStack,
    Portal,
    PopoverContent,
    PopoverTrigger,
    Popover,
    VStack,
    useMediaQuery, Divider, useOutsideClick, useDisclosure
} from "@chakra-ui/react";
import _ from "lodash";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";

import {useGetAddressCollection} from "../../../services/queries/collections/useGetAddressCollection";
import {useAutomaticTyping} from "../../../hooks/hooks";
import WholePageSpinner from "../../Modals/WholePageSpinner";
import {isEmailValid, isEthereumAddress, isSolanaAddress, validateAddress} from "../../../utils/validation-util";
import NeonButton from "../../Atoms/NeonButton/NeonButton";
import {BUTTON_COLORS} from "../../../CONSTANTS/color_constants";
import {TRANSITION_ANIMATION, MotionText, MotionBox, MotionLink, CHAINS} from "../../../CONSTANTS/animation_constants";
import {truncateAddress, isMobile} from "../../../utils/helper-util";
import {connectWallet} from "../../../utils/web3Onboard-util"
import Carousel from "../../Organism/Carousel";
import {
    BellAlert,
    Mail,
    Twitter,
    Discord, InfoIcon,
} from "../../../Icons/Icon";
import NeonText from "../../Atoms/Neon/NeonText";
import AirdropManagerLogo from "../../Atoms/AirdropManagerLogo";
import {MAIN_MENU} from "../../../CONSTANTS/ui_data";
import {isLocalHost} from "../../../utils/localhost-util";
import Subscribe from "../../Molecule/Subscribe";
import {useSubscribeAddress} from "../../../services/queries/signUp/useSubscribeAddress";

const AirDropManagerLanding = () => {
    const navigate = useNavigate();
    const timeout = useRef(null);
    const inputref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [isOpenMenu, setOpenMenu] = useState(false)
    const [input, setInput] = useState<string>("")
    const [isError, setIsError] = useState<any>({});
    const [walletAccountAddress, setWalletAccountAddress] = useState<any>();
    const [isCellular, setIsCellular] = useState(false)
    const [isLoadingBtn, setIsLoadingBtn] = useState(false)
    const [activeInput, setActiveInput] = useState(false)
    const [addressText, setAddressText] = useState("")
    const [emailText, setEmailText] = useState("")
    const [chain, setChain] = useState("")
    const [subscriptionError, setSubscriptionError] = useState({})
    const toast = useToast();
    localStorage.setItem('chakra-ui-color-mode', 'dark')
    const [isLargerThan640] = useMediaQuery('(min-width: 640px)', {ssr: false})
    const inputToast = "input-toast";
    const addressToast = "address-toast";
    const { isLoading, status, refetch, data} = useGetAddressCollection(input);
    const { isLoading: isWalletAccountAddressLoading, status: walletAccountAddressStatus, refetch: walletAccountAddressRefetch, data: walletAccountAddressData} = useGetAddressCollection(walletAccountAddress);
    const {mutate: subscribeAddress, status: subscriptionProcessStatus} = useSubscribeAddress(emailText, addressText, chain)
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
        if(isLocalHost() && MAIN_MENU.length === 5) MAIN_MENU.push({name: "Analysis", icon: InfoIcon})
    }, [])

    useEffect(() => {
        if(walletAccountAddress) walletAccountAddressRefetch(walletAccountAddress)
        setIsCellular(isMobile())
    }, [walletAccountAddress])

    useEffect(() => {
        if (((status === "success" && _.isEmpty(data))) && !toast.isActive(inputToast)) {
            toast({
                id: inputToast,
                title: "Sorry!",
                description: "Entered address is wrong or has no data",
                status: 'info',
                duration: 5000,
                isClosable: true,
            })
        }
        if(status === "success" && !_.isEmpty(data)) navigateToOverview(input)
    }, [status, data])

    useEffect(() => {
        if(isLoading || isWalletAccountAddressLoading) {
            timeout.current = setTimeout(() => {
                toast({
                    id: "long-fetch",
                    title: "Please wait",
                    description: "We're working on it...",
                    status: 'info',
                    duration: 5000,
                    isClosable: true,
                })
                }, 5000)
        }
        if(!isLoading && !isWalletAccountAddressLoading) {
            clearTimeout(timeout.current)
        }
    }, [isLoading, isWalletAccountAddressLoading])

    useEffect(() => {
        if (((walletAccountAddressStatus === "success" && _.isEmpty(walletAccountAddressData))) && !toast.isActive(addressToast)) {
            toast({
                id: addressToast,
                title: "Sorry!",
                description: "Entered address has no data",
                status: 'info',
                duration: 5000,
                isClosable: true,
            })
        }
        if(walletAccountAddressStatus === "success" && !_.isEmpty(walletAccountAddressData)) navigateToOverview(walletAccountAddress)
    }, [walletAccountAddressData, walletAccountAddressStatus])

    const carousel = useMemo(() => {
            return <Carousel/>
    }, [])

    const handleInputChange = (e:any) => {
        setInput(e.target.value)
        const validation = validateAddress(e)
        setIsError(validation)
    }

    const navigateToOverview = (addressString: string) => navigate(`/address-overview/${addressString}`, {
        state: {
            address: addressString
        },
    })
    const navigateToForms = () => navigate(`/form`);

    const navigateToDrops = () => navigate(`/drops`)

    const navigateToHistoriograph = () => navigate(`/historiograph`)

    const navigateToTimelines = () => navigate(`/timelines`)

    const navigateToAnalysis = () => navigate('/analysis')

    const getAccount = async () => {
        setIsLoadingBtn(true)
        const account = await connectWallet()
        setIsLoadingBtn(false)
        const address = await account.getAddress()
        setWalletAccountAddress(address)
    }

    const executeMenuClick = (type: string) => {
        switch(type) {
            case "NFT Magicians Web":
                return window.open("https://nftmagicians.xyz/")
            case "NFT Universe (WIP)":
                return navigateToHistoriograph()
            case "Drop Import Form":
                return navigateToForms()
            case "Extension":
                return window.open(`https://chrome.google.com/webstore/detail/nft-airdrop-manager/ohalndninddkaceoghngcpcmeeboppkm`)
            case "Airdrops / Claims":
                return navigateToDrops()
            case "Analysis":
                return navigateToAnalysis()
            default:
                return () => {}
        }
    }

    const menuIcons = (typeName: string) => {
        return (
            <Box onClick={() => typeName} boxSize={["30px", "30px", "30px"]} position={"relative"} marginLeft={"2"} marginRight={"5"}>
                <Icon as={typeName}/>
            </Box>
        )
    }

    useOutsideClick({
        // @ts-ignore
        ref: inputref,
        handler: () => setActiveInput(false),
    })

    const saveChain = (chain: string) => {
        setChain(chain)
    }

    const checkSubscriptionData = () => {
        const emailValid = isEmailValid(emailText);
        if(!emailValid) return setSubscriptionError({emailError: true, errorMessage: "Wrong email format" });
        if(chain === "") return setSubscriptionError({selectionError: true, errorMessage: "Please select chain"})
        if(chain === CHAINS.SOLANA) {
            const checkSolana = isSolanaAddress(addressText)
            if(!checkSolana) return setSubscriptionError({
                addressError: true,
                errorMessage: "Wrong SOL address!"
            })
        }
        if(chain === CHAINS.ETHEREUM) {
            const checkEthereum = isEthereumAddress(addressText)
            if(!(checkEthereum || (addressText.length > 6 && addressText.includes(".eth")))) return setSubscriptionError({
                addressError: true,
                errorMessage: "Wrong ETH address!"
            })
        }
        setSubscriptionError({})
        subscribeAddress()
    }

    return (
        <Center
            flexDirection={"column"}
            marginTop={["10", "10", "5", "0"]}
        >
            <Flex alignItems={"center"} paddingRight={"10"} paddingLeft={"5"} width={"100%"} justifyContent={"space-between"}>
                    <AirdropManagerLogo hover={false}/>
                    <MotionText as={motion.div} marginX={"30"}
                                initial={{ y: "10%", opacity: 0, scale: 0.7 }}
                                animate={{ y: 0, opacity: 1, scale: 1, transition: { duration: 0.2, ease: "easeInOut"}}}
                                exit={{ y: "-10%", scale: 1, opacity: 0, transition: TRANSITION_ANIMATION.EXIT }}
                                fontSize={["md","3xl","4xl","5xl"]} fontStyle={"italic"} fontWeight="300" textShadow={"0 0 0.125em #fff"} fontFamily={"Exo2"}
                                marginTop={["5","5","5","5"]} marginBottom={"10"}
                    >
                        Your place for every NFT airdrop
                    </MotionText>
                    <HStack zIndex={10}>
                        <HStack width={"50"} justifyContent={"space-between"}>
                            <Subscribe
                                setEmailText={setEmailText}
                                setAddressText={setAddressText}
                                loader={subscriptionProcessStatus}
                                onSaveChain={saveChain}
                                subscribe={checkSubscriptionData}
                                error={subscriptionError}
                            />
                            <Hamburger toggled={isOpenMenu} onToggle={setOpenMenu}/>
                        </HStack>
                        {isOpenMenu &&
                            <MotionBox initial={{ opacity: 0,}}
                                       animate={{ opacity: 1, transition: { duration: 0.5, ease: "easeInOut"}}}
                                       exit={{ opacity: 0, transition: TRANSITION_ANIMATION.EXIT }}
                                       zIndex={2}
                                       borderRadius={"20px"}
                                       w={['calc(80vw)', 'calc(60vw)', 'calc(40vw)', `calc(25vw)`]}
                                       paddingY={"2"} paddingLeft={["1", "2", "5", "5"]}
                                       backgroundColor={"black"}
                                       position={"absolute"}
                                       top={100}
                                       right={10}
                                       border={"0.1rem solid #fff"}
                            >
                                {MAIN_MENU.map((d: any, i: number) => {
                                    return (
                                        <HStack cursor={"pointer"} key={`${i}-${d}`} zIndex={3} _hover={{backgroundColor: "black", opacity: 0.2}} onClick={() => executeMenuClick(d.name)} marginY={"5"} justifyContent={"left"}>
                                            {menuIcons(d.icon)}
                                            <NeonText fontSize={"lg"} textValue={d.name} textShadow={"0 0 0.1em #fff"}/>
                                        </HStack>
                                    )
                                })}
                                <Divider marginBottom={"3"}/>
                                <Stack
                                    direction={"row"}
                                    marginLeft={["-2","-2","-4","-4"]}
                                    justifyContent={"space-evenly"}

                                >
                                    <Popover trigger={"hover"}>
                                        <PopoverTrigger>
                                            <MotionLink
                                                as={motion.div}
                                                onClick={() => {}}
                                            >
                                                <BellAlert props={{color: "grey"}}/>
                                            </MotionLink>
                                        </PopoverTrigger>
                                        <Portal>
                                            <PopoverContent border={"0.5px"} borderColor={"white"} boxShadow={["0 0 0.25em #fff"]} borderRadius={"20"}>
                                                <Box borderRadius={"20"} backgroundColor={"black"} boxSize={"inherit"} borderColor={"white"} border={"2px"} padding={"5"} boxShadow={["inset 0 0 0.25em 0 #fff"]}>
                                                    <NeonText textValue={"Alerts subscription incoming!"} fontSize={"sm"} />
                                                </Box>
                                            </PopoverContent>
                                        </Portal>
                                    </Popover>
                                    <MotionLink
                                        as={motion.div}
                                        onClick={() => window.open(`https://discord.com/invite/FW8WspKAhP`, '_blank')}
                                    >
                                        <Icon as={Discord}/>
                                    </MotionLink>
                                    <MotionLink
                                        as={motion.div}
                                        onClick={() => window.open(`https://twitter.com/magiciansnft`, '_blank')}
                                    >
                                        <Icon as={Twitter}/>
                                    </MotionLink>
                                    <MotionLink
                                        as={motion.div}
                                        onClick={() => window.open('mailto:contact@nftmagicians.xyz')}
                                    >
                                        <Icon as={Mail}/>
                                    </MotionLink>
                                </Stack>
                            </MotionBox>
                        }
                    </HStack>
            </Flex>
            <VStack justifyContent={"space-between"}>
                {/*<NeonText textShadow={"0 0 0.125em #fff"} marginTop={"50"} fontSize={"2xl"} fontWeight={"400"} fontStyle={"italic"} textValue={"Our wallet service is currently unavailable"}/>*/}
                <Box>
                    <VStack>
                        <MotionBox
                            initial={{ y: "10%", opacity: 0, scale: 0.3 }}
                            animate={{ y: 0, opacity: 1, scale: 1, transition: { duration: 0.2, ease: "easeInOut", delay: 0.2}}}
                            exit={{ y: "-10%", scale: 1, opacity: 0, transition: TRANSITION_ANIMATION.EXIT}}
                            marginBottom="5" h='calc(10vh)' w='calc(60vw)' borderRadius={20}
                            // boxShadow={["0 0 0.75em #fff"]}
                            marginTop={"10"}
                        >
                                <FormControl h={"inherit"} w={"inherit"} isInvalid={isError.error} borderRadius={"inherit"} boxShadow={activeInput ? isError.error ? ["0 0 0.75em red"] : ["0 0 0.75em #04d9ff"] : ["0 0 0.75em white"]}>
                                    <Input
                                        boxShadow={["inset 0 0 0.75em #fff"]}
                                        borderColor={"white"}
                                        _focus={isError.error ? {borderColor: "white", boxShadow: ["inset 0 0 0.75em red"]} : {borderColor: "white", boxShadow: ["inset 0 0 0.75em #04d9ff"]}}
                                        value={input}
                                        ref={inputref}
                                        onChange={(e) => {
                                            handleInputChange(e)
                                        }}
                                        onClick={() => setActiveInput(true)}
                                        onKeyPress={(e) => {
                                            if(e.code === "Enter" || e.code === "NumpadEnter") {
                                                if(!isError.error && input !== "") {
                                                    // @ts-ignore
                                                    refetch(input)
                                                }
                                            }
                                        }}
                                        fontFamily={"Exo2"} _placeholder={{fontWeight: "500", textShadow: "0 0 0.07em #fff" }}
                                        fontSize={"xl"} h={"inherit"} w={"inherit"} borderRadius={"inherit"}
                                        fontStyle={"italic"} variant={"filled"} placeholder={useAutomaticTyping(50, "Enter ETH/SOL address . . .")}
                                    />
                                    <FormErrorMessage marginLeft={4}>{isError.errorMessage}</FormErrorMessage>
                                </FormControl>
                        </MotionBox>
                        {!isCellular && <MotionBox initial={{ opacity: 0, }}
                                                   animate={{ opacity: 1, transition: { duration: 0.5, ease: "easeInOut", delay: 1.2}}}
                                                   exit={{ opacity: 0, transition: TRANSITION_ANIMATION.EXIT }}>
                            <NeonText fontSize={"xl"} fontWeight={"300"} fontStyle={"italic"} textValue={"OR"}/>
                        </MotionBox>}
                    </VStack>
                    <VStack position={"relative"} direction={"row"} spacing={"5"} marginTop={"1.3rem"} marginBottom={"1.3rem"} >
                        {!isCellular ?
                            <NeonButton glowingEffect={true} isLoading={isLoadingBtn} shouldAnimate={true} fontSize={isLargerThan640 ? "3xl" : "2xl"}
                                        boxShadowColor={BUTTON_COLORS.NEON_BLUE}
                                        textShadowColor={BUTTON_COLORS.NEON_RED}
                                        children={walletAccountAddress ? truncateAddress(walletAccountAddress) : "Connect Wallet"}
                                        onClick={getAccount}/> :
                            <NeonButton
                                glowingEffect={true}
                                isLoading={isLoadingBtn} shouldAnimate={true} fontSize={"4xl"}
                                boxShadowColor={BUTTON_COLORS.NEON_BLUE}
                                textShadowColor={BUTTON_COLORS.NEON_RED}
                                children={"Enter"}
                                // @ts-ignore
                                onClick={() => refetch(input)}
                            />
                        }
                    </VStack>
                </Box>
                {carousel}
            </VStack>
            {(isLoading || isWalletAccountAddressLoading) && <WholePageSpinner height={"100%"} width={"100%"}/>}
        </Center>
    )
}

export default AirDropManagerLanding;
