import * as React from "react";
import {Text} from "@chakra-ui/react";

interface NeonTextProps {
    textValue: string,
    fontSize?: string | string[],
    // fontWeight: string,
    // textShadow: string,
    marginBottom?: string,
    marginLeft?: string,
    textShadow?: string,
    marginTop?: string,
    fontWeight? :string,
    marginRight?: string
    color?: string,
    fontStyle?: string,
    maxW?: string
}

const NeonText: React.FC<NeonTextProps> = ({   color = "white",
                                                textValue,
                                               textShadow = "0 0 0em #fff",
                                               marginTop= "0",
                                               fontWeight = "600",
                                               marginBottom= "0",
                                               marginRight = "0",
                                               fontSize = "md",
                                               marginLeft ="0",
                                               fontStyle = "normal",
                                                maxW= "100%"
})  => {
    return (
        <Text maxW={maxW} color={color} marginRight={marginRight} marginLeft={marginLeft} marginTop={marginTop} marginBottom={marginBottom} fontStyle={fontStyle} fontWeight={fontWeight} fontFamily={"Exo2"} textShadow={textShadow} fontSize={fontSize}>{textValue}</Text>
    )
};

export default NeonText;