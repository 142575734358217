import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";

export interface NftDropsSlice {
    scrollY: number;
}

const initialState: NftDropsSlice = {
    scrollY: 0,
}

export const nftDropsSlice = createSlice({
    name: "nftDropsData",
    reducers: {
        saveNftDropsPositionY: (state, action) => {
            state.scrollY = action.payload
        },
    },
    initialState,
})

export const { saveNftDropsPositionY } = nftDropsSlice.actions;

export const positionY = (state: RootState) => state.nftDropsData.scrollY

export default nftDropsSlice.reducer