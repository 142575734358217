// @ts-nocheck
import React from 'react';
import Draggable from 'react-draggable';
import {Box} from "@chakra-ui/react";

const DraggableBox = ({ index, item, top = 30, left = 30 }) => {
    return (
            <Draggable>
                <Box key={index} position={"absolute"} top={top} left={left}>
                    {item}
                </Box>
            </Draggable>
        );
    };

export default DraggableBox;