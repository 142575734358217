import axios from "axios";
import apiUrl from "../api-url";

export const getAiAnalysis = async (page: number) => {
    // @ts-ignore
    const { data } = await axios.get(`${apiUrl}/owner-analysis`, {
        params: {
            page_length: 10,
            page
        },
        headers: {
            "Authorization": "ne moze nam niko nista"
        }
    })
    return data;
};