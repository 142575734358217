
// COLLECTIONS
export const GET_ADDRESS_COLLECTION = "GET_ADDRESS_COLLECTION"
export const GET_COLLECTION_BY_NAME = "GET_COLLECTION_BY_NAME";

// NFT DROPS
export const POST_NFT_DROP_FORM = "POST_NFT_DROP_FORM";
export const GET_NFT_DROPS = "GET_NFT_DROPS";
export const GET_ALL_DROPS = "GET_ALL_DROPS";
export const GET_SINGLE_NFT_DROP = "GET_SINGLE_NFT_DROP";
export const REPORT_NFT_DROP = "REPORT_NFT_DROP";
export const REPORTED_NFT_DROP_MESSAGE = "REPORTED_NFT_DROP_MESSAGE";

// ANALYSIS
export const GET_AI_ANALYSIS = "GET_AI_ANALYSIS";

// OWNERS && ALIASES
export const GET_OWNER_FOR_ALIAS = "GET_OWNER_FOR_ALIAS";
export const GET_TIMELINE_FOR_OWNER = "GET_TIMELINE_FOR_OWNER";
export const GET_ULTIMATE_OWNER = "GET_ULTIMATE_OWNER";
export const GET_COLLECTION_BY_CONTRACT = "GET_COLLECTION_BY_CONTRACT"

// SUBSCRIPTION
export const SUBSCRIBE = "SUBSCRIBE"


// COINS
export const GET_COIN_LIST = "GET_COIN_LIST";

