import {useMutation, useQueryClient} from "react-query";
import {useToast} from "@chakra-ui/react";
import {SUBSCRIBE} from "../../queryConstants";
import {subscribeAddress} from "../../axios/subscription";
import {add} from "lodash";

export const useSubscribeAddress = (emailText: string, addressText: string, chain: string) => {
    const queryClient = useQueryClient();
    const toast = useToast()
    return useMutation([SUBSCRIBE], () => subscribeAddress(emailText, addressText, chain), {
        onSuccess: async data => {
            toast({
                status: "success",
                title: data.message,
                description: `Check your email to confirm subscription!`,
                isClosable: true,
            })
            await queryClient.invalidateQueries({
                queryKey: [SUBSCRIBE],
                // refetchInactive: true
            })
        },
        onError: (e: { response: { data: { message: string}} }) => {
            toast({
                status: `error`,
                title: "Subscription unsuccessful!",
                description: `${e.response.data}`,
                duration: 2000,
                isClosable: true,
            })
        },
    });
}