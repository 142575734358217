import {STANDARD_DROP_TYPE} from "../CONSTANTS/string_constants";

const formatDigits = (value: any, percentage?: boolean, fractionDigits?: number) => {
    const formattedString = Number(value)
    let number
    number = new Intl.NumberFormat("us-US", {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }).format(formattedString)
    if(percentage) number = Number(number) * 100
    return number
}

const capitalize = (str: string) => {
    if(!str) return
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const dateFormatter = (d: string) => {
    if(!d) return
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    // console.log("d", d);
    const date = new Date(d);

    // @ts-ignore
    const dateTimeFormat = new Intl.DateTimeFormat('en-GB', options);
    // console.log("dateTimeFormat.format(date)", dateTimeFormat.format(date));
    return dateTimeFormat.format(date)
}
/**
 *
 * @param typeOfDrop
 */
const typeOfDrop = (drop: {action: string, name: string}) => {
    if(STANDARD_DROP_TYPE.includes(drop.action)) {
        if(drop.action === "airdrop") return "Attempt to claim";
        return `${capitalize(drop.action)} ${drop.name}`
    }
    // Regular expression to match only digits (0-9) or dollar sign ($)
    const regex = /^[\d$]+$/;
    // Test the string against the regular expression
    if(regex.test(drop.action)) return `Spend ${drop.action} ${drop.name}`
    return `${drop.name}`
}

export {
    formatDigits,
    capitalize,
    dateFormatter,
    typeOfDrop
}

