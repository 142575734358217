import * as React from "react";
import {Box, Button} from "@chakra-ui/react";
import {motion} from "framer-motion";
import {TRANSITION_ANIMATION} from "../../../CONSTANTS/animation_constants";

const MotionBox = motion(Box)

interface NeonButtonProps {
    onClick(): any,
    children: string | JSX.Element,
    paddingX?: string,
    paddingY?: string | string[],
    fontSize?: string | string[],
    shouldAnimate?: boolean,
    boxShadowColor?: string,
    textShadowColor?: string,
    marginLeft?: string,
    marginTop?: string,
    marginRight?: string,
    marginBottom?: string,
    disabled?: boolean,
    isLoading?: boolean,
    hover?: {},
    active?: {},
    glowingEffect?: boolean,
    border?: string,
    borderRadius?: string,
}

const NeonButton : React.FC<NeonButtonProps> = ({   onClick,
                                                    textShadowColor= "white",
                                                    boxShadowColor= "white",
                                                    shouldAnimate = false,
                                                    children,
                                                    paddingX = "7",
                                                    paddingY = "10",
                                                    fontSize = "2xl",
                                                    marginLeft = "0",
                                                    marginTop = "0",
                                                    marginRight="0",
                                                    marginBottom = "0",
                                                    disabled= false,
                                                    isLoading = false,
                                                    glowingEffect = false,
                                                    hover,
                                                    active,
                                                    border = "0.2rem solid #fff",
                                                    borderRadius = "20"
}) => {
    let hoverEffect = hover
    let activeEffect = active
    let boxShaddowEffect = ""
    let textShadowEffect = ""
    if(glowingEffect) {
        textShadowEffect = `-0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff, 0 0 2rem ${textShadowColor}, 0 0 4rem ${textShadowColor}, 0 0 6rem ${textShadowColor}, 0 0 8rem ${textShadowColor}`
        boxShaddowEffect = `0 0 0.5em #fff, inset 0 0 0.5em #fff, 0 0 2rem ${boxShadowColor}, inset 0 0 2rem ${boxShadowColor}, 0 0 4rem ${boxShadowColor}, inset 0 0 4rem ${boxShadowColor}`
        hoverEffect = {boxShadow: `0 0 0.5em #fff, inset 0 0 0.5em #fff, 0 0 2rem ${boxShadowColor}, inset 0 0 2rem ${boxShadowColor}, 0 0 4rem ${boxShadowColor}, inset 0 0 4rem ${boxShadowColor}`, textShadow: `0 0 2rem #fff`}
        activeEffect = {boxShadow: `0 0 0.5em #fff, inset 0 0 2em #fff, 0 0 4rem ${textShadowColor}, inset 0 0 4rem ${textShadowColor}`, textShadow: `0 0 2rem ${textShadowColor}`}
    }

    return (
        <MotionBox
            cursor={"pointer"}
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, transition: { duration: 0.5, ease: "easeInOut", delay: 1.2}}}
            exit={{ opacity: 0, transition: TRANSITION_ANIMATION.EXIT }}
        >
            <Button
                isLoading={isLoading}
                isDisabled={disabled}
                onClick={onClick}
                as={motion.div}
                animate={shouldAnimate ? {
                    opacity: [1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 1, 0, 0, 1, 1],
                    transition: {duration: 5, times: [0, 0.19, 0.2, 0.21, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.54, 0.55, 0.56, 0.57, 1], repeat: Infinity}
                }: {}}
                _hover={hoverEffect}
                _active={activeEffect}
                boxShadow={boxShaddowEffect}
                textShadow={textShadowEffect}
                paddingX={paddingX}
                paddingY={paddingY}
                fontSize={fontSize}
                borderRadius={borderRadius}
                fontFamily={"Exo2"}
                fontStyle={"italic"}
                fontWeight={"500"}
                border={border}
                marginLeft={marginLeft}
                marginRight={marginRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
            >{children}</Button>
        </MotionBox>
    )
}

export default NeonButton;

