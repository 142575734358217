import {Box, Text, CloseButton} from "@chakra-ui/react";
import {TOOLTIP_COLORS} from "../../CONSTANTS/color_constants";
import * as React from "react";
import {formatDigits} from "../../utils/formatter";

interface TooltipProps {
    index: string,
    onClose: any,
    data: {
        additional_data: {
            name: string,
        },
        stats: {
            total_supply: number,
            floor_price: number,
            accounting_unit: string,
            volume_24h: number,
            volume_change_24: number,
            num_owners: number,
            total_listed: number,
        }
    }
}

const Tooltip: React.FC<TooltipProps> = ({index, onClose, data}) => {

    const color = () => {
        if(data.stats.volume_change_24 < 0) return "red"
        if(data.stats.volume_change_24 > 0) return "green"
    }

    return (
        <Box
            key={`${index}`}
            position={"absolute"}
            backgroundColor={TOOLTIP_COLORS.BACKGROUND}
            border={"2px solid rgba(255,255,255, 0.5)"}
            borderRadius={"5px"}
            padding={"5px"}
            cursor={"grab"}
            // boxShadow={["0 0 1.25em #fff"]}
            boxShadow={["2px 4px 0.75em rgba(255,255,255,0.5)"]}
        >
            <Box left={-3.5}
                 top={-3.5} border={"0.5px solid rgba(255,255,255, 0.5)"} justifyContent={"center"} alignItems={"center"} backgroundColor={"black"} position={"absolute"} width={"6"} height={"6"} borderRadius={"12"}>
                <CloseButton
                    size='sm'
                    onClick={() => onClose()}
                />
            </Box>
            {data.additional_data.name && <Text color={TOOLTIP_COLORS.TEXT} fontWeight={"normal"} whiteSpace={"nowrap"} >Name: <Text color={"white"} display={"inline"} fontWeight={"bold"}>{data.additional_data.name}</Text></Text>}
            {/*<Text color={"white"} fontWeight={"bold"} whiteSpace={"nowrap"}>Items: <Text color={"white"} fontWeight={"bold"} display={"inline"}>{data.stats.total_supply}</Text></Text>*/}
            {data.stats.floor_price && <Text color={TOOLTIP_COLORS.TEXT} fontWeight={"normal"} whiteSpace={"nowrap"}>FP: <Text color={"white"} fontWeight={"bold"} display={"inline"}>{`${data.stats.floor_price} ${data.stats.accounting_unit}`}</Text></Text>}
            {data.stats.volume_24h && <Text color={TOOLTIP_COLORS.TEXT} fontWeight={"normal"} whiteSpace={"nowrap"}>Volume 24h: <Text color={"white"} fontWeight={"bold"} display={"inline"}>{`${formatDigits(data.stats.volume_24h)} ${data.stats.accounting_unit}`}</Text></Text>}
            {data.stats.volume_change_24 && <Text color={TOOLTIP_COLORS.TEXT} fontWeight={"normal"} whiteSpace={"nowrap"}>Volume Change 24h: <Text color={color()} fontWeight={"bold"} display={"inline"}>{`${formatDigits(data.stats.volume_change_24, true)} %`}</Text></Text>}
            {data.stats.num_owners && <Text color={TOOLTIP_COLORS.TEXT} fontWeight={"normal"} whiteSpace={"nowrap"}>Owners: <Text color={"white"} fontWeight={"bold"} display={"inline"}>{data.stats.num_owners}</Text></Text>}
            {data.stats.total_listed && <Text color={TOOLTIP_COLORS.TEXT} fontWeight={"normal"} whiteSpace={"nowrap"}>Listed: <Text color={"white"} fontWeight={"bold"} display={"inline"}>{data.stats.total_listed}</Text></Text>}
        </Box>
    )
}
export default Tooltip;