import * as React from "react";
import {
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import qr_code from "../../Images/eth_qrcode.png"
import NeonText from "../Atoms/Neon/NeonText";
import placeholder from "../../Images/palceholder.png";

interface ModalToDonateProps {
    isOpen: boolean,
    onClose: any,
    title: string,
}

const ModalToDonate: React.FC<ModalToDonateProps> = ({
                                                 isOpen,
                                                 onClose,
                                                 title,
                                             }) => {
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const closeModal = () => {
        return onClose()
    }

    return (
        <>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={closeModal}
            >
                <ModalOverlay />
                <ModalContent fontFamily={"Exo2"} borderRadius={20} border={"2px solid rgba(255,255,255, 0.5)"} backgroundColor={"black"} alignItems={"center"}>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Image fallbackSrc={placeholder} borderRadius={"8px"} boxSize={["100px", "150px", "200px", "300px"]} src={qr_code} />
                    </ModalBody>
                    <ModalFooter>
                        <NeonText textValue={"0x3cf86F56db2758Eca0c8667F04A37D3209A3ca7a"}/>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ModalToDonate;