import axios from "axios";
import apiUrl from "../api-url";



export const getCollectionByName = async (page: number, name: string) => {
    // @ts-ignore
    const { data } = await axios.get(`${apiUrl}/collection/?name=${name}`, {
        params: {
            page_length: 15,
            page
        },
        headers: {
            "Authorization": "ne moze nam niko nista"
        }
    });
    return data
};

export const getAddressCollection = async (page: number, userAddress: string, nftName: string | null, nftNumber: number | null) => {
        // ?ignore_cache=true
        // @ts-ignore
        const response = await axios.get(`${apiUrl}/address/${userAddress}?source=web`, {
            params: {
                page_length: 10,
                page,
                nft_name: nftName,
                nft_number: nftNumber
            },
            headers: {
                "Authorization": "ne moze nam niko nista"
            }
        });
    return response
};