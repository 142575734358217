import {getNftDrops, getAllDrops} from "../../axios/nftDrops-service";
import { useQuery, useInfiniteQuery } from 'react-query';
import { GET_NFT_DROPS, GET_ALL_DROPS } from "../../queryConstants";

export const useGetAllDrops = (dropName: any) => {
    return useInfiniteQuery([GET_ALL_DROPS, dropName], ({pageParam = 1}) => getAllDrops(pageParam, dropName), {
        getNextPageParam: (lastPage, allPages) => {
            const nextPage =
                lastPage.length === 15 ? allPages.length + 1 : undefined;
            return nextPage;
        },
        staleTime: Infinity,
        // enabled: true
    });
};


export const useGetNftDrops = () => {
    return useQuery([GET_NFT_DROPS], ({pageParam = 1}) => getNftDrops(pageParam), {
        staleTime: Infinity,
        // enabled: true
    });
};