import * as React from "react";
import {useEffect, useState} from "react";
import {MotionBox, TRANSITION_ANIMATION} from "../../CONSTANTS/animation_constants";
import {FormControl, FormErrorMessage, Input, Textarea} from "@chakra-ui/react";
import {useAnimationControls} from "framer-motion";

interface FormInputProps {
    onKeyDown({}): any,
    onChange(e: any): any,
    animation: boolean,
    placeholder: string,
    input: string,
    left?: string,
    position?: string,
}

const FormInput: React.FC<FormInputProps> = ({
                                                 onKeyDown,
                                                 animation,
                                                 placeholder,
                                                 input,
                                                 onChange,
                                                 left = "0",
                                                 position = "static",

}) => {
    const controls = useAnimationControls()

    // console.log("FormInput animation", animation);

    useEffect(() => {
        if(animation) { controls.start({ opacity: 1, zIndex: 10, width: "25vw", x: 0, transition: { duration: 0.2, ease: "easeInOut", delay: 0.2} })
        } else {
            controls.start({opacity: 0, width: 0, x: "-10%", transition: {duration: 0.2, ease: "easeInOut", delay: 0.2}})
        }
    }, [animation])

    const handleInputChange = (e:any) => {
        onChange(e)
    }

    return (
        <MotionBox
            initial={{ x: "-10%", opacity: 0, width: 0, zIndex: 0 }}
            animate={controls}
            exit={{ x: "-10%", opacity: 0, width: 0, zIndex: 0, transition: TRANSITION_ANIMATION.EXIT }}
            h='calc(10vh)' w='calc(25vw)'
            marginBottom={"5"}
            left={left} position={position}
        >
            <FormControl
                h={"inherit"}
                w={"inherit"} borderRadius={"inherit"}
                borderColor={"#fff"}
            >
                {placeholder !== "Description" ?
                <Input
                    variant='flushed'
                    focusBorderColor="#04d9ff"
                    value={input}
                    onChange={(e) => {
                        handleInputChange(e)
                    }}
                    onKeyDown={(e) => {
                        if(e.code === "Enter" || e.code === "NumpadEnter") {
                            // @ts-ignore
                            onKeyDown()
                        }
                    }}
                    fontFamily={"Exo2"} _placeholder={{fontWeight: "500", textShadow: "0 0 0.07em #fff" }}
                    fontSize={["md","md","xl","xl", "xl"]} h={"inherit"} w={"inherit"} borderRadius={"inherit"}
                    fontStyle={"italic"} placeholder={placeholder}
                /> :
                    <Textarea
                        variant='flushed'
                        focusBorderColor="#04d9ff"
                        value={input}
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                        onKeyDown={(e) => {
                            if(e.code === "Enter" || e.code === "NumpadEnter") {
                                // @ts-ignore
                                onKeyDown()
                            }
                        }}
                        fontFamily={"Exo2"} _placeholder={{fontWeight: "500", textShadow: "0 0 0.07em #fff" }}
                        fontSize={["md","md","xl","xl", "xl"]} h={"inherit"} w={"inherit"} borderRadius={"inherit"}
                        fontStyle={"italic"} placeholder={placeholder}
                    />
                }
                {/*<FormErrorMessage marginLeft={4}>{isError.errorMessage}</FormErrorMessage>*/}
            </FormControl>
        </MotionBox>
    )
}

export default FormInput;