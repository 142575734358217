import axios from "axios";
import apiUrl from "../api-url";

export const subscribeAddress = async (emailText: string, addressText: string, chain: string) => {
    const { data } = await axios.post(`${apiUrl}/email/subscription?email_address=${emailText}&blockchain=${chain}&blockchain_address=${addressText}`, {},
        // @ts-ignore
        {
            headers: {
                "Authorization": "ne moze nam niko nista"
        }
    })
    console.log("data", data);
    return data;
};