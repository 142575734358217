// @ts-nocheck
import React, {useEffect, useRef} from "react";
import {Box, useMediaQuery} from "@chakra-ui/react";
import {create, hierarchy, select, tree, each} from "d3";
import _ from "lodash";
import {useWindowDimensions} from "../../hooks/hooks";
import NeonText from "../Atoms/Neon/NeonText";
import whiteBorderCircle from "../../Images/white_border_circle.png"
import {formatDigits, capitalize} from "../../utils/formatter";
import {TOOLTIP_COLORS} from "../../CONSTANTS/color_constants";
import {isSafari} from "../../utils/helper-util";


const PrerequisiteTree = ({ data = {}, readyForUpdate, transform, widthMultiplicator = 0.9 }) => {
    const prerequisiteSvgRef = useRef();
    const { height, width } = useWindowDimensions();
    const duration = 750;
    let i = 0;
    const [isLargerThan640] = useMediaQuery('(min-width: 640px)', {ssr: false})
    // Creates a curved (diagonal) path from parent to the child nodes
    const diagonal = (s, d) => {
        return `M ${s.y} ${s.x},
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`
    }

    const getBB = (selection) => {
        selection.each(function(d){d.bbox = this.getBBox()})
    }

    useEffect(() => {
        if(_.isEmpty(data) || !readyForUpdate) return
        const svg = select(prerequisiteSvgRef.current);
        svg.selectAll("*").remove() // add this before the append.

        const dataNodes = data.children.reduce((totalNodes, nodeInChildren) => {
            return totalNodes + nodeInChildren?.children?.length;
        }, 0);

        const addHeight = dataNodes > 6 ? (dataNodes - 5) * 20 : 0;

        const svgWidth =  width * widthMultiplicator
        const svgHeight = height * 50/100 + addHeight * 8
        const margin = {top: 0, right: svgWidth/100, bottom: 0, left: svgWidth/100}
        const radius = svgWidth/20

        svg.style("font-family", "Exo2")
            .attr("font-weight", "600")
            .style("text-shadow", "0 0 0.2em #fff")
            .attr("width", svgWidth)
            .attr("height", svgHeight * 1.3)
            .attr("viewBox", [-svgWidth/5, 0, svgWidth, svgHeight])
            .append("g")
            .attr("transform", "translate("
                + margin.left + "," + margin.top + ")")
            .append("defs")
            .append("clipPath")
            .attr("id", "avatar-clip")
            .append("circle")
            .attr("r", radius)

        if(transform) {
            if(isSafari()) {
                svg.style('-webkit-transform', 'scale(-1, 1)')
            } else {
                svg.style('transform', 'scale(-1, 1)')
            }
        }

        const root = hierarchy(data, function(d) { return d.children; });

        // declares a tree layout and assigns the size
        const treemap = tree().size([svgHeight, svgWidth]);
        root.x0 = svgHeight / 2;
        root.y0 = 0;

        // Assigns the x and y position for the nodes
        const treeData = treemap(root);

        // console.log("PREREQUISITE treeData", treeData);

        // Compute the new tree layout.
        const nodes = treeData.descendants(),
            links = treeData.descendants().slice(1);

        // console.log("nodes", nodes);
        // console.log("links", links);

        // Normalize for fixed-depth.
        nodes.forEach(function(d){ d.y = d.depth * radius * 5});

        // ****************** Nodes section ***************************

        // Update the nodes...
        const node = svg.selectAll('g.node')
            .data(nodes, function(d) {return d.id || (d.id = ++i); });

        // Create a tooltip
        const tooltip = create('rect')
            .attr('class', 'tooltip')
            .style("position", "absolute")
            .style("background-color", TOOLTIP_COLORS.BACKGROUND)
            .style("border", "1px solid rgba(255,255,255, 0.3)")
            .style("border-radius", "5px")
            .style("padding", "5px")


        // Enter any new modes at the parent's previous position.
        const nodeEnter = node.enter().append('g')
            .attr('class', 'node')
            .attr("transform", function(d) {
                return "translate(" + root.y0 + "," + root.x0 + ")";
            })
            .on("mouseover",
                function (event, d: any) {
                    select('body').append(() => tooltip.node())
                    tooltip.html(`<p>${d.data.name}</p>`)
                    tooltip.style("color", TOOLTIP_COLORS.TEXT)
                }
            )
            .on("mousemove", (event, d: any) => {
                tooltip
                    .style("left", `${event.pageX + 100}px`)
                    .style("top", `${event.pageY - 100}px`);
            })
            .on("mouseout", function() {
                tooltip.remove()
            })
            // .style('filter', (d) =>{
            //     const hasChildren = d.children || d._children
            //     return !hasChildren ? 'drop-shadow(2px 0px 4px rgba(255,255,255, 0.5))' : null
            // });


        // Add Circle for the nodes
        nodeEnter.append('image')
            .attr('xlink:href', function (d) {
                if(d.data.name.indexOf("Option") !== -1) return whiteBorderCircle
                return d.data.image
            })
            .attr('transform', transform ? 'scale(-1, 1)' : null)
            .attr("x", function(d) {
                return -radius;
            })
            .attr("y", function(d) {
                return -radius;
            })
            .attr("height", radius * 2)
            .attr("width", radius * 2)
            .attr("clip-path", "url(#avatar-clip)")

        // Add labels for the nodes
        nodeEnter.append('text')
            .attr("dy", (d) => {
                if(d.data.name.indexOf("Option") !== -1) return ".35em"
                if(d.data?.children?.length > 1) return "2em"
                    return transform ? "1.75em" : ".45em"
            })
            .attr('transform', transform ? 'scale(-1, 1)' : null)
            .attr("x", function(d) {
                if(d.data.name.indexOf("Option") !== -1) return 0
                if(d.data?.children?.length > 1) return 3*radius
                return radius;
            })
            .attr("y", function(d) {
                if(d.data.name.indexOf("Option") !== -1) return 0
                const hasChildren = d.children || d._children;
                return hasChildren ? radius * 1.5 : 0;
            })
            .style('font-size', isLargerThan640 ? '20px' : "10px")
            .attr("text-anchor", function(d) {
                if(d.data.name.indexOf("Option") !== -1) return "middle";
                return d.children || d._children ? "end" : "start";
            })
            .clone(true)
            .text(function(d) {
                if(d.data.name.indexOf("Option") !== -1) {
                    const name = d.data.name.replace("Option", "#")
                    return name
                }
                if(d.data.type === "ERC20") return capitalize(d.data.name)
                if(d.data.name.length > 50) {
                    const newName = d.data.name.slice(0, 35)
                    return `${newName}...`
                }
                if(d.data.amount) return `${d.data.amount} ${d.data.name}`
                return `${d.data.name}`
            })
            .call(getBB)
            .attr("stroke-linejoin", "round")
            .attr("fill", "white")

        nodeEnter.insert("rect","text")
            .attr('transform', transform ? 'scale(-1, 1)' : null)
            .attr("x", function(d){return d.bbox.x})
            .attr("y", function(d){return d.bbox.y})
            .attr("width", function(d){return d.bbox.width})
            .attr("height", function(d){return d.bbox.height})
            .style("fill", "black")

        // actions
        nodeEnter.append('text')
            .attr("dy",transform ? "-0.75em" : "-0.55em")
            .attr("x", function(d) {
                // console.log("PREREQUISITE METHOD", d);
                return d.children || d._children ? -radius : radius;
            })
            .attr("text-anchor", function(d) {
                return d.children || d._children ? "end" : "start";
            })
            .text(function(d) {
                if(d.data.type === "ERC20") return formatDigits(d.data.action)
                if(d.data.name === "POAP" && d.data.id.includes("0x22c1f6050e56d2876009903609a2cc3fef83b415") && d.data.additionalCriteria) {
                    return  d.data.additionalCriteria.eventId
                }
                if(d.data.id === "custom/") return d.data.website
                return d.data.action
            })
            .attr('transform', transform ? 'scale(-1, 1)' : null)
            .style('font-size', isLargerThan640 ? '20px' : "10px")
            .attr("stroke-linejoin", "round")
            .attr("fill", "white")

        // Add Icon to explain action
        // nodeEnter.append('svg')
        //     .attr('width', 50)
        //     .attr('height', 50)
        //     .html('<path fill="red" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.528 17.972c-.29.426-.77.6-1.318.6H7.79c-.547 0-1.028-.174-1.318-.6-.29-.426-.398-1.002-.284-1.62l1.644-5.896C7.11 8.61 7.505 8 7.79 8h.392c.286 0 .68.61.82 1.456l1.643 5.896c.113.618.005 1.194-.285 1.62z" fill="#000"/>');


        // UPDATE
        const nodeUpdate = nodeEnter.merge(node);

        // Transition to the proper position for the node
        nodeUpdate.transition()
            .duration(duration)
            .attr("transform", function(d) {
                const hasChildren = d.children && d.children.length > 0;
                const y = d.y + radius + radius/2
                return hasChildren ? "translate(" + d.y + "," + d.x + ")" : "translate(" + y + "," + d.x + ")"
            });

        // Update the node attributes and style
        nodeUpdate.select('circle.node')
            .attr('r', 10)
            .style("fill", function(d) {
                return d._children ? "red" : "#fff";
            })
            .attr('cursor', 'pointer');

        // Remove any exiting nodes
        // console.log("node 111111", node);
        const nodeExit = node.exit().transition()
            .duration(duration)
            .attr("transform", function(d) {
                return "translate(" + root.y + "," + root.x + ")";
            })
            .remove();

        // console.log("node 2222", node);

        // // On exit reduce the node circles size to 0
        // nodeExit.select('circle')
        //     .attr('r', 1e-6);
        //
        // // On exit reduce the opacity of text labels
        // nodeExit.select('text')
        //     .style('fill-opacity', 0);

        // ****************** links section ***************************

        // Update the links...
        const link = svg.selectAll('path.link')
            .data(links, function(d) { return d.id; });

        // Enter any new links at the parent's previous position.
        const linkEnter = link.enter().insert('path', "g")
            .attr("class", "link")
            .attr("fill", "none")
            .attr("stroke-width", isLargerThan640 ? 3 : 1)
            .attr("stroke", "white")
            .attr('d', function(d) {
                const o = {x: root.x0, y: root.y0}
                return diagonal(o, o)
            }).attr("marker-start", (d) => {
                const hasChildren = d.children && d.children.length > 0;
                if(!hasChildren) return "url(#arrow)"
            })

        // Add the arrow marker to the SVG
        if(!transform) {
            svg.append("defs")
                .append("marker")
                .attr("id", "arrow")
                .attr("viewBox", "0 0 10 10")
                .attr("refX", 5)
                .attr("refY", 5)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .style("fill", "white")
                // .attr("orientation", "auto-reverse")
                .append("path")
                .attr("d",  "M 0 0 L 10 5 L 0 10 z")
        }


        // UPDATE
        const linkUpdate = linkEnter.merge(link);

        // Transition back to the parent element position
        linkUpdate.transition()
            .duration(duration)
            .attr('d', function(d){ return diagonal(d, d.parent) });

        // Remove any exiting links
        link.exit().transition()
            .duration(duration)
            .attr('d', function(d) {
                const o = {x: root.x, y: root.y}
                return diagonal(o, o)
            })
            .remove();

        // // Store the old positions for transition.
        // nodes.forEach(function(d){
        //     d.x0 = d.x;
        //     d.y0 = d.y;
        // });
        return () => {
            tooltip.remove();
        };
    }, [data, width])

    return (
        <Box paddingTop={_.isEmpty(data) ? "20" : "0"} style={_.isEmpty(data) ? { marginTop: "5rem", marginBottom: "5rem" }: {}}>
            {_.isEmpty(data) ? <NeonText textShadow={"0 0 0.5em #fff"} marginLeft={["0", "50", "50", "200"]} fontSize={"3xl"} textValue={"Your prerequisite tree will appear here..."}/>
                : <svg ref={prerequisiteSvgRef}></svg>
            }
        </Box>
    );
}

export default PrerequisiteTree;